import { BigCalendarEvent } from "./calendarUtil";
import { CustomEventProps } from "./CustomEvent";
import {
  faBed, faBuildingColumns, faBurger, faChampagneGlasses, faComment, faIceCream,
  faMartiniGlassEmpty, faMugSaucer, faPersonWalkingLuggage,
  faPlaneArrival, faPlaneDeparture, faStar, faTriangleExclamation, faUtensils
} from '@fortawesome/free-solid-svg-icons';
import { faComment as faRegularComment } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from '@mui/material';
import { getAttractionIcon } from '../chat/AttractionIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DivWithPopover } from '../../widget/DivWithPopover';
import { getImagePlace } from "trip-util";

export function CustomEventIcon(props: CustomEventProps) {
  const { event, overview, location, prevLocation, typeOfAttraction, eventType, hasNotes } = props;
  const bigEvent = event as BigCalendarEvent;
  const eventLocation = location!;

  function wrapInContainer(inner: any) {
    return <div className='icon-container'>{inner}</div>;
  }

  if(!overview && eventType === 'hotel') {
    return wrapInContainer(<FontAwesomeIcon icon={faBed} className='no-click' />);
  }

  if(overview) {
    return wrapInContainer(location && prevLocation && location !== prevLocation
      ? (
        <a href={`https://www.rome2rio.com/s/${prevLocation}/${location}`} target='_blank'>
          <FontAwesomeIcon icon={faPersonWalkingLuggage} />
        </a>
      )
      : (
        <a href={`https://www.google.com/search?q=${eventLocation}+attractions`} target='_blank'>
          <FontAwesomeIcon icon={faBuildingColumns} />
        </a>
      )
    );
  }

  const travel = !overview && eventType?.startsWith('travel-');

  if(travel) {
    return wrapInContainer(
      <FontAwesomeIcon icon={bigEvent.arriving ? faPlaneArrival : faPlaneDeparture} className='no-click' />);
  }

  if(event.end && event.start && event.end.getHours() - event.start.getHours() < 0.5) {
    return wrapInContainer(
      <Tooltip title="There may not be enough time for this activity.">
        <FontAwesomeIcon icon={faTriangleExclamation} className='no-click' />
      </Tooltip>
    );
  }

  let link;
  let icon = faStar;

  if(typeOfAttraction && bigEvent.eventType === 'activity') {
    link = `https://www.google.com/search?q=${encodeURIComponent(event.title + ',' + eventLocation)}`;
    icon = getAttractionIcon(typeOfAttraction);
  }
  else if(!(event as BigCalendarEvent).fromItinerary && bigEvent.eventType === 'activity' ||
    bigEvent.eventType === 'food') {
    let foodIcon = faStar;

    switch(bigEvent.foodType) {
      case 'restaurant':
        if(bigEvent.timeOfDay === 'morning') {
          foodIcon = faMugSaucer;
        }
        else if(bigEvent.timeOfDay === 'afternoon') {
          foodIcon = faBurger;
        }
        else {
          foodIcon = faUtensils;
        }
        break;
      case 'bar':
        foodIcon = faMartiniGlassEmpty;
        break;
      case 'market':
        foodIcon = faIceCream;
        break;
      case 'street':
        foodIcon = faChampagneGlasses;
        break;
    }

    link = `https://www.google.com/search?q=${encodeURIComponent(event.title + ',' + eventLocation)}`;
    icon = bigEvent.eventType === 'food' ? foodIcon : faStar;
  }

  let eventIcon;
  let iconContainer;

  if(hasNotes) {
    iconContainer = <div className='icon-container composite-icon'>
      <FontAwesomeIcon icon={icon} />
      <FontAwesomeIcon size='sm' icon={faComment} className='small-icon' color='#f5f5f5' />
      <FontAwesomeIcon size='sm' icon={faRegularComment} className='small-icon' color='#31741d' />
    </div>;
  }
  else {
    iconContainer = wrapInContainer(
      <FontAwesomeIcon icon={icon} />
    );
  }

  eventIcon = <a href={`https://www.google.com/search?q=${encodeURIComponent(event.title + ',' + eventLocation)}`}
    target='_blank'>
    {iconContainer}
  </a>;

  const fullPlace = getImagePlace((event as BigCalendarEvent).title, eventLocation);

  // add popover thumbnail
  if((bigEvent.fromItinerary || bigEvent.eventType === 'activity' || bigEvent.eventType === 'food') && eventIcon) {
    return <DivWithPopover anchor={eventIcon}>
      <img src={`/image?place=${fullPlace}`} alt="Thumbnail" style={{ width: '250px', height: '150px' }} />
    </DivWithPopover>
  }

  return null;
}
