import { AppToolbar } from '../component/AppToolbar';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider } from '@mui/material';
import { CityGuide } from '../component/guide/CityGuide';
import "./CityGuidePage.scss";

export function CityGuidePage(props: {}) {
  const { country, city } = useParams();
  const navigate = useNavigate();

  return (
    <div className='city-guide-page'>
      <AppToolbar current='outline' />
      <Divider />
      <div className='city-guide-page-container'>
      <CityGuide location={`${city}, ${country}`}/>
      </div>
    </div>
  );
}
