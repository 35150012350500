export function shuffleArray(featuredTrips: any[]): any[] {
  const shuffledArray = featuredTrips.slice();

  for(let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}

export function getLocalStorageValue(key: string, defaultValue: any = '') {
  const saved = localStorage.getItem(key);
  return saved !== null ? saved : defaultValue;
};

// check if searchStr is contained in text at word boundary
// @wholeWord true to check at both start and end
export function containsWordBoundary(text: string, searchStr: string, wholeWord: boolean): boolean {
  // Escape the search string to treat it literally in the regex
  const escapedSearchStr = searchStr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const regex = new RegExp(`\\b${escapedSearchStr}${wholeWord ? '\\b' : ''}`, 'i');
  return regex.test(text);
}

