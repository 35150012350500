import React, { MouseEvent } from 'react';
import { Typography, Stack, Tooltip } from "@mui/material";
import { Attraction } from "../../../../shared/types/itinerary";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./AttractionItem.scss";
import { getAttractionIcon } from '../chat/AttractionIcon';
import { getImagePlace } from 'trip-util';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

interface AttractionItemProps {
  attraction: Attraction;
  selected: boolean;
  onSelect: (attraction: Attraction) => void;
  onDelete: () => void;
}

function AttractionItem0(props: AttractionItemProps) {
  const { attraction, selected, onSelect, onDelete } = props;
  const activityTypes = attraction.typeOfAttraction?.split('|')?.map(a => getAttractionIcon(a)) || [];
  const placeFull = getImagePlace(attraction.place, attraction.location);
  const imageUrl = `/image?place=${placeFull}`;
  const q = encodeURIComponent(attraction.place + ',' + attraction.location);

  function click() {
    onSelect(attraction);
  }

  function clickDelete(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();
    onDelete();
  }

  return (
    <Stack direction={'row'} className={`attraction-container ${selected ? 'selected' : ''}`} onClick={click}>
      <Stack sx={{ flexGrow: 1000 }}>
        <Stack direction={'row'} spacing={1} className='title-container'>
          <a href={`https://google.com/search?q=${q}`} target='_blank' className='title1'>
            <div className='attraction-title'>
              <div className='type-icon-container'>
                {activityTypes.slice(0, 1).map((icon, i) => (
                  <FontAwesomeIcon icon={icon} key={i} size="lg" className='attraction-type-icon' />
                ))}
              </div>
              <div className={`attraction-place`}>
                {attraction.place}
              </div>
            </div>
          </a>
          {selected ?
            <Tooltip title='Selected' className='select-icon'>
              <FontAwesomeIcon icon={faCheck} className='select-icon' />
            </Tooltip> :
            <Tooltip title='Ignore' className='delete-icon'>
              <FontAwesomeIcon icon={faXmark} className='delete-icon' onClick={clickDelete} />
            </Tooltip>
          }
        </Stack>
        <Typography variant='body2' color='text.secondary'>
          {attraction.activity}
        </Typography>
      </Stack>
      <div className='image-container'>
        <a href={`https://google.com/search?q=${q}&tbm=isch`} target='_blank'>
          <img src={imageUrl} alt="Image1" className='responsive-image' />
        </a>
      </div>
    </Stack>
  );
}

export const AttractionItem = React.memo(AttractionItem0);