// handle output (from gpt-4o): ```yaml{...}```
export function stripBackquotes(str: string): string {
  if(str.includes('```')) {
    str = str.trim();

    if(str.startsWith('```') && str.endsWith('```')) {
      str = str.substring(3, str.length - 3);

      if(str.startsWith('json') || str.startsWith('yaml')) {
        str = str.substring(4);
      }
    }

    return str.trim();
  }

  return str;
}

