import React from 'react';
import { Typography, Stack, Chip} from "@mui/material";
import { Attraction } from "../../../../shared/types/itinerary";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./GuideAttraction.scss";
import { getAttractionIcon } from '../chat/AttractionIcon';
import { getImagePlace } from 'trip-util';

interface GuideAttractionProps {
  rank?: number;
  attraction: Attraction;
}

function GuideAttraction0(props: GuideAttractionProps) {
  const { rank, attraction } = props;
  const activityTypes = attraction.typeOfAttraction?.split('|')?.map(a => getAttractionIcon(a)) || [];
  const placeFull = getImagePlace(attraction.place, attraction.location);
  const imageUrl = `/image?place=${placeFull}`;
  const q = encodeURIComponent(attraction.place + ',' + attraction.location);

  return (
    <Stack direction={'row'} className={`attraction-container`}>
      <Stack sx={{ flexGrow: 1000 }}>
        <Stack direction={'row'} spacing={1} className='title-container'>
          <a href={`https://google.com/search?q=${q}`} target='_blank' className='title1'>
            <div className='attraction-title'>
              <Chip label={rank}/>
              <div className='type-icon-container'>
                {activityTypes.slice(0, 1).map((icon, i) => (
                  <FontAwesomeIcon icon={icon} key={i} size="lg" className='attraction-type-icon' />
                ))}
              </div>
              <div className={`attraction-place`}>
                {attraction.place}
              </div>
            </div>
          </a>
        </Stack>
        <Typography variant='body2' color='text.secondary'>
          {attraction.activity}
        </Typography>
      </Stack>
      <div className='image-container'>
        <a href={`https://google.com/search?q=${q}&tbm=isch`} target='_blank'>
          <img src={imageUrl} alt="Image1" className='responsive-image' />
        </a>
      </div>
    </Stack>
  );
}

export const GuideAttraction = React.memo(GuideAttraction0);