import * as yaml from 'js-yaml';
import { stripBackquotes } from 'trip-util';
import { ChatRecord } from '../../../page/TripChat';
import React from 'react';

// extract question field from the yaml object
export function getQuestion(str: string): string {
  if(str.startsWith('```yaml')) {
    return (yaml.load(stripBackquotes(str)) as any).question || str;
  }

  return str;
}

// extract message from reply. this shouldn't be necessary if ai follows the instruction.
export function getMessage(str: string): string {
  // yaml, don't parse into yaml or newlines will be lost
  if(str.startsWith('```yaml') && str.endsWith('```')) {
    str = stripBackquotes(str).trim();

    if(str.startsWith('message: "') && str.endsWith('"')) {
      str = str.substring(10, str.length - 1);
    }
  }

  return str;
}

export function keepLastGroupOfBubbles(records: ChatRecord[]): ChatRecord[] {
  // Find the starting index of the last group of adjacent bubbles
  let lastBubbleStart = -1;

  for(let i = records.length - 1; i >= 0; i--) {
    if(records[i].textType === 'bubble') {
      lastBubbleStart = i; // Mark the start of the last bubble group
    } else if(lastBubbleStart !== -1) {
      break; // Stop when we find a non-bubble after the last group
    }
  }

  // If there is no group of bubbles, return the array as-is
  if(lastBubbleStart === -1) {
    return records;
  }

  // Keep all non-bubble objects and the last group of bubbles
  return records.filter((_, index) => records[index].textType !== 'bubble' || index >= lastBubbleStart);
}

// combine records with indivial bubble elements into a single records of array of elements
export function combineElementMessages(records: ChatRecord[]): ChatRecord[] {
  const result: ChatRecord[] = [];
  let tempElements: any[] = [];

  for(const record of records) {
    if(React.isValidElement(record.message)) {
      // If the message is an Element, add it to the temporary array
      tempElements.push(<div key={tempElements.length}>{record.message}</div>);
    }
    else {
      // If there are collected elements, push them as a new record
      if(tempElements.length > 0) {
        result.push({ ...record, message: [...tempElements] });
        tempElements = [];
      }
      // Push the current record as-is
      result.push(record);
    }
  }

  // If there are remaining collected elements, push them as a final record
  if(tempElements.length > 0) {
    result.push({ ...records[records.length - 1], message: [...tempElements] });
  }

  return result;
}