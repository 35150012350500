import { useEffect, useState, KeyboardEvent } from 'react';
import {
  TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Autocomplete
} from '@mui/material';
import "./OvernightDialog.scss";
import { Trip, TripDay } from '@shared/types/itinerary';

interface EventDialogProps {
  open?: boolean;
  day: TripDay;
  prevStay?: string;
  trip: Trip;
  onClose?: (ok: boolean) => void;
};

export function OvernightDialog(props: EventDialogProps) {
  const { open, day, prevStay, trip, onClose } = props;
  const [overnightCity, setOvernightCity] = useState<string>('');
  const nextStay = trip.itinerary?.[day.day]?.overnightCity;
  const options = Array.from(new Set([prevStay, day.overnightCity, nextStay,
    ...trip.itinerary!.map(d => d.locations.map(loc => loc.location).concat([d.overnightCity])).flat()]))
    .filter(a => !!a);

  useEffect(() => {
    setOvernightCity(day.overnightCity);
  }, [day]);

  const handleSubmit = () => {
    day.overnightCity = overnightCity;
    onClose && onClose(true);
  };

  const handleClose = () => {
    onClose && onClose(false);
  };

  function keyDown(event: KeyboardEvent) {
    if(event.key === "Enter") {
      handleSubmit();
    }
  }
  return (
    <Dialog open={!!open} onClose={handleClose} className='overnight-dialog'>
      <DialogTitle>Overnight City</DialogTitle>
      <DialogContent className='overnight-dialog-container'>
        <Autocomplete autoFocus freeSolo options={options} className='overnight-field'
          renderInput={(params) => <TextField {...params} label="Overnight City" />} fullWidth
          value={overnightCity} inputValue={overnightCity} onKeyDown={keyDown}
          onInputChange={(event, value) => setOvernightCity(value ?? '')}
          onChange={(event, value) => setOvernightCity(value ?? '')} />
      </DialogContent>
      <DialogActions className='command-container'>
        <Button variant='contained' onClick={handleClose} color='secondary'>Cancel</Button>
        <Button variant='contained' onClick={handleSubmit} disabled={!overnightCity}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OvernightDialog;
