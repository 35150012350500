import { useEffect, useState } from 'react';
import {
  TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import "./DayDialog.scss";
import { TripDay } from '@shared/types/itinerary';
import { getLocationsString } from 'trip-util';

interface DayDialogProps {
  open?: boolean;
  day: TripDay;
  onClose?: () => void;
  onSubmit?: (notes: string, transportation: string) => void;
};

export function DayDialog(props: DayDialogProps) {
  const { open, day, onClose, onSubmit } = props;
  const [notes, setNotes] = useState<string>('');
  const [transportation, setTransportation] = useState<string>('');

  useEffect(() => {
    setNotes(day?.notes ?? '');
    setTransportation(day?.transportation ?? '');
  }, [day]);

  const handleSubmit = () => {
    onSubmit && onSubmit(notes, transportation);
    onClose && onClose();
  };

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Dialog open={!!open} onClose={handleClose} className='day-dialog'>
      <DialogTitle>{getLocationsString(day)}</DialogTitle>
      <DialogContent className='day-dialog-container'>
        <TextField margin="dense" label="Notes" multiline={true} fullWidth className='notes-field'
          rows={8} value={notes} onChange={event => setNotes(event.target.value)} />
        {!!transportation ?
          <TextField margin="dense" label="Transportation" multiline={true} fullWidth className='transportation-field'
            rows={5} value={transportation} onChange={event => setTransportation(event.target.value)} /> : null
        }
      </DialogContent>
      <DialogActions className='command-container'>
        <Button variant='contained' onClick={handleClose} color='secondary'>Cancel</Button>
        <Button variant='contained' onClick={handleSubmit}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DayDialog;
