import { Link, useLocation, useNavigate } from 'react-router-dom';
import './AppToolbar.scss';
import { useUserContext } from '../service/UserContext';
import BroadcastMessage from '../widget/BroadcastMessage';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGear } from '@fortawesome/free-solid-svg-icons';

interface AppToolbarProps {
  current?: string
}

export function AppToolbar(props: AppToolbarProps) {
  const { current } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const user = useUserContext();

  function login() {
    if(!user.isSignedIn()) {
      const originalUri = location.pathname + (location.search ?? '');
      const state = { originalUri };
      navigate(`/login`, { state });
    }
    else {
      user.logout().then(a => navigate('/'));
    }
  }

  return (
    <div>
      <div className='app-toolbar'>
        <div className='title-div'>
          { /* <img src="/images/hat.svg" width={55} height={35} /> */}
          <Link to="/" className='bar-title'>Explore. Plan. Your Perfect Trip</Link>
          <div className="beta-label">Beta</div>
        </div>
        <Link to="/featured-trips" replace className={`bar-item ${current === 'featuredTrips' ? 'selected-item' : ''}`}>
          Be Inspired
        </Link>
        <Link to="/create" className='bar-item'>
          <FontAwesomeIcon icon={faUserGear}/>
          Explore & Plan
        </Link>
        <Link to="/mytrips" className={`bar-item ${current === 'myTrips' ? 'selected-item' : ''}`}>
          My Itineraries
        </Link>
        {user.getUser()?.admin ?
          <Link to="/admin" className={`bar-item ${current === 'admin' ? 'selected-item' : ''}`}>
            Admin
          </Link> : null
        }
        <div className='right-icon-container'>
          <Tooltip title='About/Contact'>
            <Link to="/about" className={`bar-item ${current === 'myTrips' ? 'selected-item' : ''}`}>
              <InfoIcon />
            </Link>
          </Tooltip>
          <Tooltip title={user.isSignedIn() ? 'Sign Out' : 'Sign In'}>
            <a onClick={login} className='bar-item'>{user.isSignedIn() ? <ExitToAppIcon /> : <AccountCircleIcon />}</a>
          </Tooltip>
        </div>
      </div>
      <div><BroadcastMessage /></div>
    </div>
  );
}