import React, { useState, useEffect } from 'react';
import './PhraseScroller.scss';

interface PhraseScrollerProps {
  phrases: string[];
  scrollInterval: number; // Time (in milliseconds) between each scroll
}

const PhraseScroller: React.FC<PhraseScrollerProps> = ({ phrases, scrollInterval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if(currentIndex < phrases.length - 1) {
      const timer = setInterval(() => {
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, phrases.length - 1));
      }, scrollInterval);

      return () => clearInterval(timer); // Cleanup on component unmount
    }
  }, [currentIndex, phrases.length, scrollInterval]);

  return (
    <div className="phrase-scroller-container">
      <div
        className="phrase-scroller"
        style={{ transform: `translateY(-${currentIndex * 30}px)` }}
      >
        {phrases.map((phrase, index) => (
          <div key={index} className="phrase-scroller-item">
            {phrase}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhraseScroller;
