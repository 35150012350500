import React, { useEffect, useRef, useState } from 'react';
import { Typography, Stack, Tooltip, Chip } from "@mui/material";
import { Attraction, TripDay } from "../../../../shared/types/itinerary";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./AttractionComponent.scss";
import axios from 'axios';
import { ImageMeta } from '../../../../shared/types/chat';
import { getAttractionIcon } from './AttractionIcon';
import { getImagePlace, getLocationCity } from 'trip-util';
import { AttractionMenuButton } from './AttractionMenuButton';
import { AttractionInfoIcon } from './AttractionInfoIcon';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { DropResult } from 'react-beautiful-dnd';

interface AttractionComponentProps {
  activity: Attraction;
  day: TripDay,
  attractionIndex: number,
  multiLocation?: boolean;
  selected?: boolean;
  compact?: boolean;
  attractionNumber?: number;
  editable: boolean;
  onDelete?: () => void;
  onReplaceAttraction?: () => void;
  onDropAttraction: (fromDay: number, fromAttraction: number, before: boolean) => void;
}

interface AttractionDragItem {
  day: TripDay;
  attraction: Attraction;
  attractionIndex: number;
}

function AttractionComponent0(props: AttractionComponentProps) {
  const { activity, day, attractionIndex, multiLocation, selected, compact, attractionNumber, editable, onDelete,
    onReplaceAttraction, onDropAttraction } = props;
  const [meta, setMeta] = useState<ImageMeta | null>(null);
  const activityTypes = activity.typeOfAttraction?.split('|')?.map(a => getAttractionIcon(a)) || [];
  const placeFull = getImagePlace(activity.place, activity.location);
  const imageUrl = `/image?place=${placeFull}`;
  const dropRef = useRef<HTMLDivElement | null>(null);

  // drag and drop between days
  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: 'ATTRACTION',
    item: { day: day, attraction: activity, attractionIndex: attractionIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const [{ isOver, isBefore }, drop] = useDrop(() => ({
    accept: 'ATTRACTION',
    canDrop: (item: AttractionDragItem, monitor: DropTargetMonitor<Attraction, DropResult>) => {
      const sameLocation = item.attraction.location === activity.location;
      const dayTrip = item.attraction.location !== item.day.overnightCity;
      // allow drop to the same city, or move day trip to any other location.
      return (sameLocation || dayTrip) &&
        (item.day.day !== day.day || item.attractionIndex !== attractionIndex);
    },
    drop: (item: AttractionDragItem, monitor) => {
      onDropAttraction(item.day.day - 1, item.attractionIndex, !!checkBefore(monitor));
      return undefined;
    },
    collect: (monitor) => {
      const before = checkBefore(monitor);
      return {
        isOver: monitor.isOver() && monitor.canDrop(),
        isBefore: before
      };
    }
  }));

  // combine drag and drop refs
  const dragDropRef = (node: HTMLDivElement | null) => {
    dragRef(node);
    drop(node);
    dropRef.current = node;
  };

  useEffect(() => {
    if(!meta) {
      axios.get<ImageMeta>(`/image-meta?place=${placeFull}`)
        .then(meta => setMeta(meta.data))
        .catch(ex => console.log(ex));
    }
  }, []);

  function checkBefore(monitor: DropTargetMonitor<AttractionDragItem, DropResult>) {
    const offset = monitor.getClientOffset();
    const zone = dropRef.current?.getBoundingClientRect();
    const before = offset && zone && offset.y < zone.y + zone.height / 2;
    return before;
  }

  function getTitle() {
    return multiLocation ? `${activity.place} (${getLocationCity(activity.location)})` : activity.place;
  }

  function deleteAttraction() {
    onDelete && onDelete();
  }

  function getDecoration(): string {
    return `${selected ? 'selected' : ''} ${isDragging ? 'dragging' : ''}` +
      ` ${isOver ? (isBefore ? 'drop-before' : 'drop-after') : ''}`
  }

  const q = encodeURIComponent(activity.place + ',' + activity.location);
  return (
    <Stack ref={dragDropRef} direction={'row'}
      className={`activity-container ${getDecoration()}`}>
      <Stack sx={{ flexGrow: 1000 }}>
        <Stack direction={'row'} spacing={1}>
          <a href={`https://google.com/search?q=${q}`} target='_blank' className='title1'>
            <div className='activity-title'>
              <div className='type-icon-container'>
                {activityTypes.slice(0, 1).map((icon, i) => (
                  <FontAwesomeIcon icon={icon} key={i} size="lg" className='activity-type-icon' />
                ))}
              </div>
              <div className={`activity-place ${compact ? 'compact-activity' : ''}`}>
                {getTitle()}
              </div>
              {attractionNumber !== undefined ?
                <Chip label={attractionNumber + 1} variant='filled' size='small'
                  sx={{ height: '21px', fontWeight: "bold" }} /> : null
              }
            </div>
          </a>
          <div className='activity-buttons'>
            {compact ?
              <AttractionInfoIcon attraction={activity} /> : <div />
            }
            <AttractionMenuButton onDelete={deleteAttraction} disabled={!editable} />
          </div>
        </Stack>
        {!compact ?
          <Typography variant='body2' color='text.secondary'>
            {activity.activity}
          </Typography> : null
        }
      </Stack>
      {!compact ?
        <div className='image-container'>
          <Tooltip title={meta?.ack}>
            <a href={`https://google.com/search?q=${q}&tbm=isch`} target='_blank'>
              <img src={imageUrl} alt="Image1" className='responsive-image' />
            </a>
          </Tooltip>
        </div> : null
      }
    </Stack>
  );
}

export const AttractionComponent = React.memo(AttractionComponent0);