import { TripSummary } from "@shared/types/summary";
import { useEffect, useState } from 'react';
import { useTripCache } from '../service/TripCache';
import './Home.scss';
import { AppToolbar } from '../component/AppToolbar';
import { HomeItem } from '../component/feature/HomeItem';
import { Backdrop } from "@mui/material";
import { Link } from "react-router-dom";
import { shuffleArray } from "../util/ui-util";
import { getDistributedCountryTrips } from "../util/featured-util";
import LoadingScreen from "../widget/LoadingScreen";

export function Home() {
  const [featuredTrips, setFeaturedTrips] = useState<TripSummary[]>();
  const tripService = useTripCache();

  useEffect(() => {
    tripService.getFeaturedTrips(setFeaturedTrips);
  }, []);

  function pick3() {
    if(!featuredTrips) {
      return [];
    }

    const shuffledArray = shuffleArray(featuredTrips);
    return getDistributedCountryTrips(shuffledArray).slice(0, 3);
  }

  return (
    <div className='home-top-container'>
      <AppToolbar />
      <div className='home-content-container'>
        {pick3().map((t, i) => <HomeItem trip={t} key={i} />)}
      </div>
      <div className='more-samples'>
        <Link to='/featured-trips'>More Sample Itineraries...</Link>
      </div>
      <Backdrop open={!featuredTrips} invisible sx={{ zIndex: 10000 }}>
        <LoadingScreen/>
      </Backdrop>
    </div>
  );
}
