import React from 'react';
import './LoadingScreen.scss'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';

interface LoadingScreenProps {
  children?: React.ReactNode
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ children }) => {
  return (
    <div className="loading-screen">
      <FontAwesomeIcon icon={faGear} size='5x' className='spinner'/>
      {children}
    </div>
  );
};

export default LoadingScreen;
