import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import './AttractionInfoIcon.scss';
import { DivWithPopover } from '../../widget/DivWithPopover';
import { AttractionPlace } from '@shared/types/chat';
import { getImagePlace } from 'trip-util';

interface AttractionInfoProps {
  attraction: AttractionPlace;
}

export function AttractionInfoIcon(props: AttractionInfoProps) {
  const { attraction } = props;
  const placeFull = getImagePlace(attraction.place, attraction.location);

  const icon = <FontAwesomeIcon icon={faCircleInfo} className='attraction-info-icon' />;
  return <DivWithPopover anchor={icon}>
      <div className='attraction-info-container'>
        <img src={`/image?place=${placeFull}`} alt="Thumbnail" style={{ width: '250px', height: '150px' }} />
        <div className='attraction-desc'>{attraction.activity}</div>
      </div>
    </DivWithPopover>;
}