import { useEffect, useState } from 'react';
import { Calendar, DateCellWrapperProps, dateFnsLocalizer, DateLocalizer, Event, View } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { format, parse, startOfWeek, getDay } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { Trip } from '../../../../shared/types/itinerary';
import { CustomEvent, CustomEventProps } from './CustomEvent';
import CustomCursor from './CustomCursor';
import { Backdrop, Snackbar } from '@mui/material';
import "./TripCalendar.scss";
import { BigCalendarEvent, dateDiff, deleteEvent, findAttraction, getPlanEvent, getRangeLocalizer, orderEvent } from './calendarUtil';
import { createPlanFromTrip } from "./planConvertion";
import { createEventsFromPlan } from "./planConvertion";
import withDragAndDrop, { EventInteractionArgs } from 'react-big-calendar/lib/addons/dragAndDrop';
import CustomDateCell from './CustomDateCell';
import EventDialog from './EventDialog';
import { Plan, PlanDay, PlanEvent } from '../../../../shared/types/plan';
import { optimizeRoute } from '../../service/chatRequests';
import { updateBigCalendarEventStart } from './updateEvents';
import LoadingScreen from '../../widget/LoadingScreen';

const DragAndDropCalendar = withDragAndDrop(Calendar);
const locales = {
  'en-US': enUS,
};

interface TripCalendarProps {
  style?: any;
  trip?: Trip;
  plan?: Plan;
  overview: boolean;
  onChange: (plan: Plan) => void;
  onTripChange: (trip: Trip) => void;
}

const defaultLocalizer = dateFnsLocalizer({
  format, parse, startOfWeek: () => startOfWeek(new Date()), getDay, locales,
});

export function TripCalendar(props: TripCalendarProps) {
  const { trip, overview, onChange, onTripChange } = props;
  const [plan, setPlan] = useState<Plan | undefined>();
  // events is transient and is always created from plan
  const [events, setEvents] = useState<BigCalendarEvent[]>([]);
  // start is save as plan.start
  const [start, setStart] = useState<Date | undefined>(undefined);
  // current displaying date
  const [calendarDate, setCalendarDate] = useState<Date | undefined>(undefined);
  const [message, setMessage] = useState<string>("");
  // for creating/editing event
  const [eventDate, setEventDate] = useState<Date | undefined>();
  const [eventDay, setEventDay] = useState<PlanDay | undefined>();
  const [editingDay, setEditingDay] = useState<boolean>(false);
  const [currEvent, setCurrEvent] = useState<BigCalendarEvent | undefined>();
  const [localizer, setLocalizer] = useState<DateLocalizer | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [orderingEvent, setOrderingEvent] = useState<number>();
  const [view, setView] = useState<View>('month');

  useEffect(() => {
    if(!plan && start && trip) {
      planChanged(createPlanFromTrip(trip, start));
    }
    else if(start && start !== plan?.start && plan) {
      planChanged({ ...plan, start: start });
    }

    if(!calendarDate) {
      setCalendarDate(start);
    }
  }, [trip, start]);

  useEffect(() => {
    if(!plan?.start) {
      setMessage("Select a date as the starting date for the trip.")
    }
    else {
      setMessage("");
      setEvents(createEventsFromPlan(plan, overview));
    }
  }, [plan, overview]);

  useEffect(() => {
    setPlan(props.plan);
    setStart(props.plan?.start);
  }, [props.plan]);

  useEffect(() => {
    if(start) {
      const localizer0 = getRangeLocalizer(start, plan);
      setLocalizer(localizer0);
    }
    else {
      setLocalizer(undefined);
    }
  }, [start, plan, calendarDate]);

  function selectDate(range: { start: Date, end: Date }): boolean {
    if(!start) {
      setStart(range.start);
      setMessage("");
    }

    return true;
  }

  function dropEvnt(args: EventInteractionArgs<Event>) {
    if(!args.start || !plan || !trip?.days || !trip?.itinerary) {
      return;
    }

    const nstart: Date = args.start as Date;
    const nidx = dateDiff(nstart, plan.start);
    const event: BigCalendarEvent = args.event as BigCalendarEvent;

    if(nidx !== event.dayIndex && plan.days[nidx]) {
      if(overview) {
        const itineraryIdx = event.dayIndex - plan.itineraryOffset;
        const fromItinerary = itineraryIdx >= 0 && itineraryIdx < trip.days;
        const toItinerary = nidx >= plan.itineraryOffset && nidx - plan.itineraryOffset < trip.days;

        if(fromItinerary !== toItinerary) {
          setMessage("Use the Explore view to change the order of the days with in the itinerary.")
          return;
        }

        const [removed] = plan.days.splice(event.dayIndex, 1);
        plan.days.splice(nidx, 0, removed);

        if(fromItinerary) {
          const [removedDays] = trip.itinerary.splice(event.dayIndex - plan.itineraryOffset, 1);
          trip.itinerary.splice(nidx - plan.itineraryOffset, 0, removedDays);
          onTripChange(trip);
        }
      }
      else {
        plan.days[event.dayIndex].events = plan.days[event.dayIndex].events.filter(e => e.title !== event.title);
        plan.days[nidx].events.push(getPlanEvent(event));
      }

      planChanged(plan);
    }
    else if(view === 'week' && plan.days[nidx]) {
      const nevents = updateBigCalendarEventStart(events.filter(d => d.dayIndex === nidx), event, args.start as Date);
      plan.days[nidx].events = nevents.map(e => getPlanEvent(e));
      planChanged(plan);
    }
  }

  function planChanged(plan: Plan) {
    const nplan = { ...plan };
    setPlan(nplan);
    onChange(nplan);
  }

  function newEventOrDay(date: Date, planDay?: PlanDay) {
    setEventDate(date);
    setEventDay(planDay);
    setEditingDay(!planDay);
  }

  function editDay(date: Date, planDay?: PlanDay) {
    setEventDate(date);
    setEventDay(planDay);
    setEditingDay(true);
  }

  function deleteDay(date: Date) {
    if(plan) {
      const dayIndex = dateDiff(date, plan.start);
      const tripDayIdx = dayIndex - plan.itineraryOffset;
      plan.days?.splice(dayIndex, 1);

      // deleting an itinerary day
      if(tripDayIdx >= 0 && trip?.itinerary && tripDayIdx < trip.itinerary.length) {
        trip.itinerary.splice(tripDayIdx, 1);
        trip.itinerary.forEach((d, i) => d.day = i + 1);
        onTripChange(trip);
      }
      // if deleting a user added day, don't shift the days left.
      else if(dayIndex < plan.itineraryOffset) {
        plan.start?.setDate(plan.start.getDate() + 1);
      }

      if(dayIndex < plan.itineraryOffset) {
        plan.itineraryOffset -= 1;
      }

      setPlan({ ...plan });
    }
  }

  function onOptimizeRoute(date: Date, planDay?: PlanDay): Promise<boolean> {
    if(plan && planDay) {
      const dayIndex = dateDiff(date, plan.start);
      setLoading(true);
      optimizeRoute(planDay).then(day => {
        setLoading(false);
        plan.days[dayIndex] = day;
        setPlan({ ...plan });
        return true;
      }).catch(e => {
        setMessage(`Failed to optimize route: ${e}`);
        setLoading(false);
      });
    }

    return Promise.resolve(false);
  }

  function onOrderEvent(date?: Date) {
    setOrderingEvent(dateDiff(date, plan?.start));
  }

  function submitEventOrDay(value: PlanEvent | PlanDay) {
    if((currEvent || eventDate) && plan) {
      const event: PlanEvent | undefined = (value as any).timeOfDay ? value as PlanEvent : undefined;
      const day: PlanDay | undefined = !event ? value as PlanDay : undefined;
      const dayIndex = currEvent ? currEvent.dayIndex : dateDiff(eventDate!, plan?.start);

      if(event) {
        const events = plan.days[dayIndex].events;
        const currIdx = events.findIndex(a => a.title === currEvent?.title && a.timeOfDay === currEvent?.timeOfDay);

        if(currIdx >= 0) {
          events[currIdx] = { ...event };
        }
        else {
          events.push({ ...event });
        }
      }
      else if(day) {
        if(dayIndex < 0) {
          plan?.start && plan.start.setDate(plan.start.getDate() - 1);
          plan.days.splice(0, 0, day);
          plan.itineraryOffset += 1;
        }
        else if(dayIndex < plan.days.length) {
          plan.days[dayIndex].title = day.title;
          plan.days[dayIndex].notes = day.notes;
          const dayN = plan.days[dayIndex].itineraryDay;

          if(dayN && trip?.itinerary && trip.itinerary[dayN - 1].notes !== day.notes) {
            trip.itinerary[dayN - 1].notes = day.notes;
            onTripChange(trip);
          }
        }
        else {
          plan.days.push(day);
        }
      }

      setEventDate(undefined);
      setEventDay(undefined);
      setPlan({ ...plan });
    }
  }

  function closeEventDialog() {
    setEventDate(undefined);
    setCurrEvent(undefined);
  }

  function MyDayCell(props: DateCellWrapperProps) {
    const date: Date = props.value;
    const dayIndex = plan ? dateDiff(date, plan.start) : -2;
    // allow adding day for days immediately before and after current days, and adding events for all existing days.
    const enableAdd = plan && (!overview && dayIndex >= -1 && dayIndex <= plan.days.length ||
      overview && (dayIndex === -1 || dayIndex === plan.days.length))
    // allow edit day location for user added days
    const enableEdit = plan && dayIndex >= 0 && dayIndex < plan.days.length;
    const enableDelete = dayIndex >= 0 && plan?.days && dayIndex < plan?.days.length;
    return CustomDateCell({
      ...props,
      planDay: plan?.days?.[dayIndex],
      ordering: orderingEvent === dayIndex,
      view: view,
      onAdd: enableAdd ? newEventOrDay : undefined,
      onEdit: enableEdit ? editDay : undefined,
      onDelete: enableDelete ? deleteDay : undefined,
      onOptimize: enableEdit ? onOptimizeRoute : undefined,
      onOrder: enableEdit ? onOrderEvent : undefined
    });
  }

  function MyEvent(props: CustomEventProps) {
    const onEdit = (event: Event) => {
      setEventDate(event.start);
      setCurrEvent(event as BigCalendarEvent);
      setEditingDay(false);
    };
    const onDelete = (event: BigCalendarEvent) => {
      if(plan) {
        deleteEvent(event, plan);
        planChanged(plan);
      }
    };

    const event: BigCalendarEvent = props.event as BigCalendarEvent;
    const planDay = plan?.days[event.dayIndex];
    const prevDay = plan?.days[event.dayIndex - 1];
    const location = event.location || planDay?.title;
    const prevLocation = prevDay?.title;
    const planEvent = planDay?.events?.[event.eventIndex];
    const prevEvent = planDay?.events?.[event.eventIndex - 1];
    const nextEvent = planDay?.events?.[event.eventIndex + 1];
    // arrival is always first, departure and hotel are always last
    const dayIndex = dateDiff(event.start!, plan?.start);
    const upEnabled = orderingEvent === dayIndex &&
      event.eventIndex > 0 && !prevEvent?.eventType?.startsWith('travel-') &&
      (event.eventType !== 'hotel' && !event.eventType?.startsWith('travel-'));
    // hotel is always last
    const downEnabled = orderingEvent === dayIndex &&
      event.eventIndex + 1 < (planDay?.events?.length ?? 0) &&
      nextEvent?.eventType !== 'hotel' &&
      (event.eventType !== 'hotel' && !event.eventType?.startsWith('travel-'));
    const moveEvent = (event: BigCalendarEvent, up: boolean) => {
      orderEvent(event, plan!, up);
      planChanged(plan!);
    };

    return CustomEvent({
      ...props,
      overview: overview,
      location: location || '',
      prevLocation: prevLocation,
      typeOfAttraction: findAttraction(event, plan, trip)?.typeOfAttraction,
      eventType: event.eventType,
      hasNotes: !!planEvent?.notes,
      onEdit: !overview ? onEdit : undefined,
      onDelete: onDelete,
      onUp: upEnabled ? (event) => moveEvent(event, true) : undefined,
      onDown: downEnabled ? (event) => moveEvent(event, false) : undefined,
    });
  }

  function navigate(event: Date) {
    setCalendarDate(event);
  }

  function onView(view: View) {
    setView(view);
  }

  function getMinDate(events: BigCalendarEvent[]): Date {
    return events.length === 0 ? new Date()
      : new Date(Math.min(...events.map(event => event.start).filter(d => !!d).map(d => d!.getTime())));
  }

  function getMaxDate(events: BigCalendarEvent[]): Date | undefined {
    return events.length === 0 ? undefined
      : new Date(Math.max(...events.map(event => event.end).filter(d => !!d).map(d => d!.getTime())));
  }

  return (
    <div className={`trip-calendar-container ${!start ? 'hide-cursor' : ''}`}>
      {!start ? <CustomCursor /> : null}
      <DragAndDropCalendar
        localizer={localizer || defaultLocalizer}
        views={['month', 'week']}
        resizable={false}
        date={calendarDate}
        min={getMinDate(events)}
        max={getMaxDate(events)}
        selectable={true}
        showAllEvents={true}
        events={start ? events : []}
        components={{
          event: MyEvent,
          dateCellWrapper: MyDayCell
        }}
        tooltipAccessor={(e: any) => ''}
        onSelectSlot={selectDate}
        onNavigate={navigate}
        onEventDrop={dropEvnt}
        onView={onView}
        style={{ ...props?.style }}
      />
      <Snackbar open={!!message} message={message} onClose={() => setMessage('')} autoHideDuration={20000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
      <EventDialog open={!!eventDate} oevent={currEvent}
        editingDay={editingDay} plan={plan}
        planDay={currEvent ? plan?.days?.[currEvent?.dayIndex] : eventDay}
        onClose={closeEventDialog} onSubmit={submitEventOrDay} />
      <Backdrop open={loading} invisible sx={{ zIndex: 10000 }}>
        <LoadingScreen/>
      </Backdrop>
    </div>
  );
}
