import { Trip } from "@shared/types/itinerary";
import { ChatRecord } from "../../../page/TripChat";
import { BubbleProcessor, BubbleResult, ChatBubble } from "./ChatBubble";
import { getLocationCity } from "trip-util";

export function createDestinationBubble(submit: (q: string, processor: BubbleProcessor) => void, trip: Trip): ChatRecord {
  const cities = Array.from(new Set(
    trip.itinerary?.map(d => d.locations.map(loc => getLocationCity(loc.location))).flat())).join(', ');
  const onclick = () => submit(`\`\`\`yaml
question: Can you suggest some other top destination cities in the area (other than ${cities})?
instruction: "return as a list of cities with description, in markdown in the format: 
  - **city,country**: description in about 100 words"
\`\`\``, processDestinationResponse);

  return {
    message: <div className='chat-bubble' key={0} onClick={onclick}>Suggest More Destinations</div >,
    textType: 'bubble',
    recurring: true,
    label: 'Suggest More Destinations'
  };
}

const processDestinationResponse = (message: string, submit: (q: string) => void): BubbleResult => {
  const { text, cities } = extractCities(message);
  // css styles defined in ChatHistory.scss

  if(cities.length) {
    const row = <div className="chat-bubble-container-row">
      {cities.map((city, idx) => (
        <ChatBubble label={city} key={idx} onAdd={() => submit(`Add an extra day for ${city} to the itinerary.`)} />
      ))}
    </div>
    return {
      text: text,
      bubbles: [{
        textType: 'bubble',
        message: row
      }]
    };
  }

  return {
    text: text,
    bubbles: []
  };
};

// extract information from markdown:
// - city: description
function extractCities(markdown: string): { text: string, cities: string[] } {
  const result: string[] = [];
  const lines = markdown.split('\n');
  let text = '';

  for(let line of lines) {
    const line2 = line.replaceAll('**', '').trim();
    // Pattern to match cities
    // - city: ...
    const dayTripPatterns = [
      /^\s*- ([^:]+):/ig,
    ];
    const dayTripMatch = dayTripPatterns.map(pattern => pattern.exec(line2)).filter(m => !!m)[0];

    if(dayTripMatch) {
      const city = dayTripMatch[1];
      result.push(city);
      line = line.replace(city, getLocationCity(city)!); // only show city name in message
    }

    text = `${text}\n${line}`;
  }

  return {
    text: text.trim(),
    cities: result
  };
}
