import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Container, Stack, TextField, IconButton, Grid, Backdrop, Chip, Snackbar
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Conversation } from "../../../shared/types/chat";
import { Trip, TripDay } from "../../../shared/types/itinerary";
import { TripComponent } from '../component/chat/TripComponent';
import { ChatHistory } from '../component/chat/ChatHistory';
import "./TripChat.scss"
import { ask, save } from '../service/chatRequests';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { TripMap } from '../component/map/TripMap';
import { TripCalendar } from '../component/calendar/TripCalendar';
import { TopToolbar } from '../component/TopToobar';
import { Plan } from '../../../shared/types/plan';
import { createPlanFromTrip } from "../component/calendar/planConvertion";
import ConfirmDialog from '../widget/ConfirmDialog';
import _ from 'lodash';
import { TripAccess } from '@shared/types/UserTrip';
import { tripCache, useTripCache } from '../service/TripCache';
import { TripData } from '@shared/types/tripInfo';
import { addNewDays, updateTransportationInfo } from '../component/chat/tripUtil';
import { popReturnUrl } from "../util/returnUrl";
import { useUserContext } from '../service/UserContext';
import { syncAddDay, syncDeleteDay, syncPlanDay } from '../component/calendar/planSync';
import { createDayTripBubble } from '../component/chat/bubble/DayTripBubble';
import { ConfirmType, TripChatConfirmDialog } from '../component/dialog/TripChatConfirmDialog';
import { getMessage, getQuestion, keepLastGroupOfBubbles } from '../component/chat/bubble/bubble-util';
import { stripBackquotes } from 'trip-util';
import { createDestinationBubble } from '../component/chat/bubble/DestinationBubble';
import { BubbleProcessor } from '../component/chat/bubble/ChatBubble';
import LoadingScreen from '../widget/LoadingScreen';
import PhraseScroller from '../widget/PhraseScroller';

export interface ChatRecord {
  message: any;
  textType: "system" | "user" | "ai" | "bubble";
  recurring?: boolean; // if bubble action should be saved and can be invoked later
  label?: string; // label that can identify this type of bubble
}

export type ViewType = 'explore' | 'plan';
export type ViewMode = 'detail' | 'compact' | 'dayOverview';

interface TripPlan {
  trip: Trip;
  plan?: Plan;
}

interface TripChatProps {
}

function TripChat(props: TripChatProps) {
  const location = useLocation();
  const { returnUrl, returnState } = useMemo(() => popReturnUrl(location), []);
  const ticket = new URLSearchParams(location.search).get('ticket');
  const unsaved0 = new URLSearchParams(location.search).get('unsaved') === 'true';
  const { _id } = useParams();
  const [unsaved, setUnsaved] = useState<boolean>(unsaved0);
  const [tripName, setTripName] = useState<string>('');
  const [currId, setCurrId] = useState<string | undefined>();
  const [records, setRecords] = useState<ChatRecord[]>([]);
  const recordsRef = useRef<ChatRecord[]>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [trip, setTrip] = useState<Trip>({});
  const tripRef = useRef<Trip>();
  const otrip = useRef<Trip>();
  const [plan, setPlan] = useState<Plan | undefined>();
  const [history, setHistory] = useState<Conversation[]>([]);
  const historyRef = useRef<Conversation[]>();
  const [selectedDays, setSelectedDays] = useState<Set<number>>(new Set());
  const [loading, setLoading] = useState<boolean>(false);
  const [viewMode, setViewMode] = useState<ViewMode>(localStorage.getItem('tripx.viewMode') as ViewMode || 'detail');
  const [message, setMessage] = useState<string>("");
  const [message2, setMessage2] = useState<string>("");
  const [currentName, setCurrentName] = useState<string>("");
  const [defaultName, setDefaultName] = useState<boolean>(false);
  const [view, setView] = useState<ViewType>('explore');
  const [tripHistory, setTripHistory] = useState<TripPlan[]>([]);
  const tripHistoryRef = useRef<TripPlan[]>();
  const [planHistory, setPlanHistory] = useState<Plan[]>([]);
  const [dirty, setDirty] = useState<boolean>(false);
  const [confirmType, setConfirmType] = useState<ConfirmType>('');
  const [readonly, setReadonly] = useState<boolean>(false);
  const [publicAccess, setPublicAccess] = useState<TripAccess>();
  const [idleChat, setIdleChat] = useState<number>(0);
  const [anonymous, setAnonymous] = useState<boolean>(false);
  const [overLimit, setOverLimit] = useState<boolean>(false);
  const [lastUnsaved, setLastUnsaved] = useState<TripPlan>();
  const bubbleProcessorRef = useRef<BubbleProcessor>();
  const navigate = useNavigate();
  const userManager = useUserContext();
  const tripService = useTripCache();
  const question_create: ChatRecord = {
    textType: "ai",
    message: "Tell me where you want to go, for how many days, and your interests. For example:"
  };
  const question_modify: ChatRecord = {
    textType: "ai",
    message: "Use the menus **\u22EE** to make changes. How would you like to modify this itinerary?"
  };
  const MAX_IDLE = 3;

  useEffect(() => {
    userManager.fetchUser().then(a => setAnonymous(!a));

    if(_id) {
      setLoading(true);
      axios.get<TripData>(`/trip/${_id}?ticket=${ticket || ''}&unsaved=${unsaved}`).then(result => {
        const tripData: TripData = result.data;

        if(!tripData.trip) {
          alert("You have no permission to access the itinerary.");
          navigate('/');
          return;
        }

        const plan: Plan = tripData.plan!;
        const trip: Trip = tripData.trip!;

        setLoading(false);
        setTripName(tripData.tripName ?? '');
        setReadonly(!!tripData.readonly);
        setPublicAccess(tripData.publicAccess);
        setTrip(trip);
        setPlan(plan);

        setDefaultName(tripData.tripName === getDefaultName(tripData.trip));
        setRecords([trip?.days ? question_modify : question_create]);
        const planClone = structuredClone(plan);
        setTripHistory(tripHistory.concat([{ trip: structuredClone(trip), plan: planClone }]));
        setPlanHistory(planHistory.concat([planClone]));

        // if already started planning stage, should continue in plan.
        if(plan?.start) {
          setView('plan');
        }
      }).catch(ex => {
        setLoading(false);
        setMessage(ex + "");
      });
    }
    else {
      setRecords([question_create]);
    }
  }, []);

  useEffect(() => {
    setCurrentName(tripName && !defaultName ? tripName : getDefaultName(trip));

    // recurring bubbles are collected and reused in ChatHistory
    // only leave the last group of bubble
    if(trip.days) {
      setRecords(keepLastGroupOfBubbles(records.filter((m, i) => !m.recurring)));
    }

    if(!otrip.current?.destinations?.length && trip?.itinerary?.length) {
      if(!_id) {
        records.push(question_modify);
      }

      setRecords([...records, createDayTripBubble(submitQuestion), createDestinationBubble(submitQuestion, trip)]);
      otrip.current = trip;
    }

    if(!unsaved) {
      saveUnsavedTrip();
    }
  }, [trip, tripName])

  useEffect(() => {
    if(currId) {
      navigate(`/open/${currId}`, { replace: true });
    }
  }, [currId]);

  useEffect(() => {
    const handleBeforeUnload = (event: { preventDefault: () => void; returnValue: string; }) => {
      if(dirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [dirty]);

  useEffect(() => {
    setSelectedDays(new Set());
    localStorage.setItem('tripx.viewMode', viewMode);
  }, [viewMode]);

  useEffect(() => {
    tripRef.current = trip;
    historyRef.current = history;
    tripHistoryRef.current = tripHistory;
    recordsRef.current = records;
  }, [trip, history, tripHistory, records]);

  const getDefaultName = (trip: Trip) => trip ? `${trip.days} Days in ${trip.destinations}` : '';

  async function submit() {
    submitQuestion(inputRef?.current?.value!);
  }

  async function submitQuestion(question: string, processor?: BubbleProcessor) {
    bubbleProcessorRef.current = processor;
    submit0(question, tripRef.current ?? trip, historyRef.current ?? history,
      tripHistoryRef.current ?? tripHistory, recordsRef.current ?? records);
  }

  async function submit0(question: string, trip: Trip, history: Conversation[], tripHistory: TripPlan[],
    records: ChatRecord[]) {
    setLoading(true);
    ask(stripBackquotes(question), history, trip)
      .then(response => {
        setLoading(false);
        setDirty(true);

        const overLimit = userManager.incrementChatCount();
        setOverLimit(overLimit);

        if(overLimit) {
          setMessage2(`You have reached chat limit for the session (60 minutes).`)
        }

        if(response.trip && !_.isEqual(response.trip, trip)) {
          setTrip(response.trip);
          setTripHistory(tripHistory.concat([{ trip: structuredClone(response.trip) }]));
          setIdleChat(0);
        }
        else {
          const idle = idleChat + 1;

          if(idle >= MAX_IDLE) {
            setConfirmType('idle-chat');
            setIdleChat(0);
          }
          else {
            setIdleChat(idle);
          }
        }

        const message = getMessage(response.message);
        let nrecords: ChatRecord[] = [...records, { message: getQuestion(question), textType: "user" }];

        if(response.message) {
          if(bubbleProcessorRef.current) {
            const { text, bubbles } = bubbleProcessorRef.current(response.message, submitQuestion);
            nrecords.push({ message: text, textType: "ai" });
            nrecords = nrecords.concat(bubbles);
            bubbleProcessorRef.current = undefined;
          }
          else {
            nrecords.push({ message: message, textType: "ai" });
          }
        }

        setRecords(nrecords);
        setHistory(history.concat([{
          question: question,
          answer: response.message
        }]).slice(-100));
        inputRef.current && (inputRef.current.value = "");
      })
      .catch(error => {
        setLoading(false);
        setMessage(error.message || error + '');
      });
  }

  function keyDown(event: React.KeyboardEvent) {
    if(event.key === 'Enter') {
      submit();
    }
  }

  function saveTrip(name: string, saveAs: boolean) {
    setDefaultName(name === getDefaultName(trip));
    // clear flag so if a user tries to close, won't get a warning.
    setTimeout(() => setDirty(false), 1000);
    const pendingSave = save(name, trip, plan, saveAs ? undefined : (currId || _id))
      .then(result => {
        setDirty(false);
        setMessage(result.message ? result.message + '' : '');
        setReadonly(false);
        tripService.setPendingChange(undefined);
        tripService.refreshTrips().then(a => tripCache.setUnsavedTrip(null));

        axios.delete(`/unsaved-trip`)
          .then(ack => setUnsaved(false))
          .catch(ex => console.log("Failed to delete trip: ", ex));

        if(result.insertedId) {
          setCurrId(result.insertedId);
        }
      })
      .catch(error => {
        console.log("Error:", error);
        tripService.setPendingChange(undefined);
        setMessage(error.message || error + '');
      });
    tripService.setPendingChange(pendingSave);
  }

  function saveUnsavedTrip() {
    if(!trip?.days || anonymous || _.isEqual(lastUnsaved, { trip, plan })) {
      return;
    }

    setLastUnsaved({ trip, plan });
    save(tripName || getDefaultName(trip), trip, plan, currId || _id, true)
      .then(result => { })
      .catch(error => setMessage(`Failed to save trip: ${error}`));
  }

  function close() {
    if(dirty) {
      setConfirmType('unsaved');
    }
    else {
      closeConfirmed();
    }
  }

  function closeConfirmed(button: string = "Yes") {
    if(button === "Yes") {
      if(confirmType === 'plan-change') {
        refreshPlan(true);
      }
      else if(confirmType === 'idle-chat') {
        setView('plan');
      }
      else if(!anonymous) {
        axios.delete(`/unsaved-trip`).then(ack => { })
          .catch(ex => setMessage(`Failed to delete trip: ${ex}`));
        navigate(returnUrl || '/mytrips', { state: returnState });
      }
      else if(_id) {
        navigate(`/outline/${_id}`);
      }
      else {
        navigate(`/featured-trips`);
      }
    }

    setConfirmType('');
  }

  function changeView(event: any, view: ViewType) {
    if(view) {
      setView(view);
    }
  }

  function tripChanged(ntrip: Trip, instruction?: string) {
    if(instruction) {
      records.push({ message: instruction, textType: "user" });
    }

    const otrip = trip;
    setDirty(true);
    setIdleChat(0);
    setTrip(ntrip);
    setTripHistory(tripHistory.concat([{ trip: structuredClone(ntrip) }]));
    saveUnsavedTrip();
    updateTransportationInfo(ntrip, otrip)
      .then(trip => trip && setTrip(trip))
      .catch(ex => setMessage('Error: ' + ex));
  }

  function dayChanged(ntrip: Trip, dayIndex: number) {
    if(plan) {
      syncPlanDay(dayIndex, ntrip, plan);
      setPlan({ ...plan });
    }

    tripChanged(ntrip);
  }

  function addDay(day: TripDay) {
    setLoading(true);
    setTripHistory(tripHistory.concat([{ trip: structuredClone(trip), plan: structuredClone(plan) }]));
    const ndays = 1;
    addNewDays(trip, day.day - 1, ndays).then(trip => {
      setTrip({ ...trip });
      setLoading(false);

      if(plan) {
        syncAddDay(day.day - 1, ndays, trip, plan);
        setPlan(plan);
      }
    }).catch(ex => setLoading(false));
  }

  function onSelectDay(day: TripDay) {
    const days = trip.itinerary?.filter(d => d.locations[0]?.location === day.locations[0]?.location).map(d => d.day - 1);
    setSelectedDays(new Set(days));
  }

  function deleteDay(day: TripDay) {
    records.push({ message: `Remove day ${day.day}, ${day.overnightCity} from the itinerary`, textType: "user" });
    records.push({ message: `Day ${day.day}, ${day.overnightCity} removed from the itinerary`, textType: "ai" });

    if(plan) {
      syncDeleteDay(day.day - 1, plan)
      setPlan(plan);
    }
  }

  function planChanged(plan: Plan) {
    setDirty(true);
    setPlan(plan);
    setPlanHistory(planHistory.concat([structuredClone(plan)]));
    tripHistory[tripHistory.length - 1].plan = structuredClone(plan);
    saveUnsavedTrip();
  }

  function undo() {
    if(view === "plan") {
      if(planHistory.length > 1) {
        planHistory.pop();
        setPlan(planHistory[planHistory.length - 1]);
      }
    }
    else {
      if(tripHistory.length > 1) {
        tripHistory.pop();
        const { trip, plan } = tripHistory[tripHistory.length - 1];

        if(trip) {
          setTrip(trip);
        }

        if(plan) {
          setPlan(plan);
        }

        history.pop();
        setHistory(history);
      }
    }
  }

  function setStart() {
    setPlan({
      itineraryOffset: plan?.itineraryOffset || 0,
      itineraryLength: plan?.itineraryLength || 0,
      days: plan?.days || [],
      start: undefined
    });
  }

  function refreshPlan(confirmed: boolean = false) {
    const nplan = createPlanFromTrip(trip, plan?.start, plan);

    if(!_.isEqual(plan, nplan) && !confirmed) {
      setConfirmType('plan-change');
    }
    else {
      planChanged(nplan);
    }
  }

  const phases = ['Understanding...', 'Reasoning...', 'Collecting Information...', 'Generating...',
    'Optimizing...', 'Generating...'];
  return (
    <Container className={`trip-container ${!trip.itinerary ? 'empty-trip' : ''}`} maxWidth={false} sx={{ margin: "10px" }}>
      <Backdrop open={loading} invisible sx={{ zIndex: 10000 }}>
        <LoadingScreen>
          <PhraseScroller phrases={loading && !_id ? phases : []} scrollInterval={2500}/>
        </LoadingScreen>
      </Backdrop>
      <Stack spacing={1} sx={{ height: 'calc(100vh - 30px)' }}>
        <TopToolbar _id={_id} trip={trip} plan={plan} close={close} newTrip={!_id && !currId} readonly={readonly}
          publicAccess={publicAccess} view={view} changeView={changeView} editable={!anonymous}
          viewMode={viewMode} setViewMode={setViewMode}
          currentName={currentName} saveTrip={saveTrip} onSetStart={setStart}
          onRefresh={plan?.start ? refreshPlan : undefined}
          onUndo={(view === 'plan' ? planHistory.length : tripHistory.length) > 1 ? undo : undefined}
          onShare={(publicAccess) => setPublicAccess(publicAccess)}
        />
        {view !== 'explore' ?
          <TripCalendar style={{ flex: 2, height: 'calc(100vh - 70px)' }} trip={trip}
            plan={plan} onChange={planChanged} onTripChange={tripChanged} overview={viewMode === 'dayOverview'} /> :
          <div className='explore-container'>
            <Grid container className='middle-container'>
              <div className='topDiv'>
                <TripComponent style={{ flex: 2, maxHeight: '100%', overflow: 'auto' }} trip={trip}
                  viewMode={viewMode} selectedDays={selectedDays} onSelect={(days) => setSelectedDays(days)}
                  editable={!anonymous}
                  onChange={tripChanged} onAdd={addDay} onDelete={deleteDay} onDayChange={dayChanged}
                />
                <TripMap trip={trip} selectedDays={selectedDays}
                  onSelect={onSelectDay} onClear={() => setSelectedDays(new Set())} />
                <Chip className='pexels-ack' variant='filled' label='Photos provided by Pexels'></Chip>
              </div>
              <ChatHistory records={records} showSample={records.length == 1 && !_id}
                addRecords={(arr) => setRecords(records.concat(arr))} />
            </Grid>
            <TextField label="Question" variant='filled' inputRef={inputRef} className='question-field'
              onKeyDown={keyDown} autoFocus disabled={anonymous || overLimit}
              InputProps={{
                endAdornment: (
                  <IconButton type="submit" onClick={submit}>
                    <SendIcon />
                  </IconButton>
                )
              }} />
            {anonymous ?
              <div className='login-overlay'>
                <Link to='/login' state={{ returnTo: window.location.href }} className='login-link'>
                  <AccountCircleIcon />
                  {trip.itinerary ? 'Sign in to chat with Chatbot' : 'Sign in to start planning your trip'}
                </Link>
              </div> : null
            }
          </div>}
      </Stack>
      <Snackbar open={!!message} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setMessage("")} message={message} />
      <TripChatConfirmDialog confirmType={confirmType} onSubmit={closeConfirmed} />
      <ConfirmDialog open={!!message2} label={message2}
        buttons={['OK']} onSubmit={() => setMessage2('')} />
    </Container >
  );
}

export default TripChat;
