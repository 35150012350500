import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuButton } from '../../widget/MenuButton';
import {
  faBed, faCalendarPlus, faCalendarXmark, faCamera, faEdit, faPlaneCircleXmark
} from '@fortawesome/free-solid-svg-icons';
import "./TripDayMenuButton.scss";
import { getLocationCity } from 'trip-util';

interface TripDayMenuButtonProps {
  disabled: boolean;
  overnightCity: string;
  onAdd?: () => void;
  onDelete?: () => void;
  onDeleteCity?: () => void;
  onDayTrip?: () => void;
  onOvernight?: () => void;
  onEdit?: () => void;
  onEditAttractions?: () => void;
}

export function TripDayMenuButton(props: TripDayMenuButtonProps) {
  const { disabled, overnightCity, onAdd, onDelete, onDeleteCity, onOvernight, onEdit, onEditAttractions } = props;
  const actions = [];

  if(onEditAttractions) {
    actions.push({
      label: 'Select Attractions',
      icon: <FontAwesomeIcon icon={faCamera}/>,
      disabled: disabled,
      action: () => onEditAttractions && onEditAttractions()
    });
  }

  if(onAdd) {
    actions.push({
      label: 'Add Day',
      icon: <FontAwesomeIcon icon={faCalendarPlus}/>,
      disabled: disabled,
      action: () => onAdd && onAdd()
    });
  }

  if(onDelete) {
    actions.push({
      label: 'Remove Day',
      icon: <FontAwesomeIcon icon={faCalendarXmark}/>,
      disabled: disabled,
      action: () => onDelete && onDelete()
    });
  }

  if(onDeleteCity) {
    actions.push({
      label: `Remove ${getLocationCity(overnightCity)}`,
      icon: <FontAwesomeIcon icon={faPlaneCircleXmark}/>,
      disabled: disabled,
      action: () => onDeleteCity && onDeleteCity()
    });
  }

  if(onOvernight) {
    actions.push({
      label: 'Change Overnight',
      icon: <FontAwesomeIcon icon={faBed}/>,
      disabled: disabled,
      action: () => onOvernight && onOvernight()
    });
  }

  if(onEdit) {
    actions.push({
      label: 'Edit Notes',
      icon: <FontAwesomeIcon icon={faEdit}/>,
      disabled: disabled,
      action: () => onEdit && onEdit()
    });
  }

  return MenuButton({ className: 'trip-day-menu-button', actions });
};
