import { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import "./Mapbox.scss";
import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';
import { mergeMapPoints } from './mergeMapPoints';

export interface MapPoint {
  num?: string; // marker number, 1, 2, 3...
  lng: number;
  lat: number;
  location: string;
  tip?: string;
  days?: number;
  secondary?: boolean;
  image?: string;
  click?: () => void;
}

interface MapboxProps {
  centerLat?: number;
  centerLng?: number;
  zoom?: number;
  width?: string;
  height?: string;
  markers?: MapPoint[];
  maxZoom?: number;
  style?: any;
}

function Mapbox0(props: MapboxProps) {
  const { centerLat, centerLng, zoom, width, height, markers, maxZoom, style } = props;
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map>();
  const [mapMarkers, setMapMarkers] = useState<mapboxgl.Marker[]>([]);
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN!;

  function fitBounds(map: mapboxgl.Map, markers: MapPoint[] | undefined) {
    if(markers?.length) {
      const minLat = Math.min(...markers.map(m => m.lat));
      const maxLat = Math.max(...markers.map(m => m.lat));
      const minLng = Math.min(...markers.map(m => m.lng));
      const maxLng = Math.max(...markers.map(m => m.lng));
      const padLat = (maxLat - minLat) * 0.1;
      const padLng = (maxLng - minLng) * 0.1;
      map.fitBounds([minLng - padLng, minLat - padLat, maxLng + padLng, maxLat + padLat]);
    }
  }

  function getImageDiv(m: MapPoint) {
    // image style is same as responsive-image in AttractionComponent.scss
    return `
      <div style='width: 250px; height: 150px'>
        <img src='${m.image}' 
          style='position: absolute; top: 50%; left: 50%; width: 100%; height: 100%; object-fit: cover; transform: translate(-50%, -50%);'>
       <div style='position: absolute; top; 0; left: 0; width: 100%; display: flex; justify-content: center;'>
         <div style='background: rgba(0,0,0,0.3); color: white'>
           <b>${m.tip}</b>
         </div>
       </div>
     </div>`;
  }

  useEffect(() => {
    const element = mapContainer.current;
    if(!element) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      map.current?.resize();
    });

    resizeObserver.observe(element);

    // Clean up observer on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    initMap();
  }, [markers]);

  function initMap() {
    if(!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current!,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [centerLng || 2.35, centerLat || 48.86],
        zoom: zoom || maxZoom || 5,
        renderWorldCopies: false
      });
      map.current.flyTo({ animate: false });
      map.current.addControl(new mapboxgl.NavigationControl());
    }

    fitBounds(map.current, markers);
    addMarkers();
  }

  function addMarkers() {
    mapMarkers.forEach(m => m.remove());

    const newMarkers: mapboxgl.Marker[] = [];
    const added: any = {};
    markers?.forEach((m, idx) => {
      const key1 = m.location;
      const key2 = `${m.lat},${m.lng}`;

      if(!added[key1] && !added[key2]) {
        const el = document.createElement('div');
        el.className = `marker ${m.secondary ? 'marker-small' : ''}`;
        el.innerHTML = `${m.num || (idx + 1)}`;

        if(!m.secondary) {
          el.style.width = el.style.height = `${((m?.days ?? 1) - 1) * 2 + 25}px`;
        }

        if(m.click) {
          el.addEventListener('click', m.click);
        }

        added[key1] = true;
        added[key2] = true;
        const loc = { lat: m.lat, lng: m.lng };
        const marker = new mapboxgl.Marker(el)
          .setLngLat(loc)
          .addTo(map.current!);
        newMarkers.push(marker);

        if(m.tip || m.image) {
          const html = m.image ? getImageDiv(m) : `<b>${m.tip}</b>`;
          const popup = new mapboxgl.Popup({
            closeButton: false
          }).setHTML(html);
          el.addEventListener('mouseenter', () => popup.addTo(map.current!).setLngLat(loc));
          el.addEventListener('mouseleave', () => popup.remove());
          //marker.setPopup(popup);
        }
      }
    });

    setMapMarkers(newMarkers);
  }

  return <div ref={mapContainer} className="mapContainer"
    style={{ ...style, width: width ?? "100%", height: height ?? "100%" }} />;
}

export const Mapbox = React.memo(Mapbox0);
