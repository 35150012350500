import { copyTransporationInfo, getTransportationLocations } from "trip-util";
import { Trip, TripDay } from "../../../../shared/types/itinerary";
import { getTopAttractions, getTransportationInfo } from "../../service/chatRequests";

export function updateTransportationInfo(trip: Trip, otrip: Trip): Promise<Trip | undefined> {
  if(trip.itinerary?.length) {
    // when itinerary is changed by user (delete/day-trip/arrange), the transportation information in the
    // original trip may not be correct. update the instruction from AI.
    return getTransportationInfo(trip, otrip).then(info => {
      if(trip.itinerary?.length) {
        copyTransporationInfo(trip, info, otrip);
        return { ...trip };
      }
    });
  }

  return Promise.resolve(undefined);
}

export function addNewDays(trip: Trip, dayIndex: number, ndays: number): Promise<Trip> {
  const day = trip.itinerary![dayIndex];
  const existingAttractions = trip.itinerary!.map(day => day.attractions).flat()
    .filter(a => a.location === day.overnightCity);
  const n = existingAttractions.length + ndays * 3;
  return getTopAttractions(day.overnightCity, n).then(topns => {
    const newAttractions = topns.filter(a => !existingAttractions.find(e => e.place === a.place));
    const newDays: TripDay[] = [];

    for(let i = 0; i < ndays; i++) {
      newDays.push({
        ...day,
        day: i + dayIndex + 1,
        locations: day.overnightCity === day.locations?.[0]?.location && day.locations?.length == 1 ? day.locations :
          [{
            location: day.overnightCity,
            latitude: newAttractions[0].latitude,
            longitude: newAttractions[0].longitude
          }],
        attractions: newAttractions.slice(i * 3, i * 3 + 3),
        transportation: '',
      });
    }

    trip.itinerary?.splice(dayIndex + 1, 0, ...newDays);
    trip.days! += ndays;
    trip.itinerary?.forEach((d, i) => d.day = i + 1);
    return trip;
  });
}