import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuButton } from '../../widget/MenuButton';
import { faRepeat, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import "./AttractionMenuButton.scss";

interface AttractionMenuButtonProps {
  disabled: boolean;
  onDelete?: () => void;
}

export function AttractionMenuButton(props: AttractionMenuButtonProps) {
  const { disabled, onDelete } = props;
  const actions = [];

  if(onDelete) {
    actions.push({
      label: 'Remove',
      icon: <FontAwesomeIcon icon={faTrashCan}/>,
      disabled: disabled,
      action: () => onDelete && onDelete()
    });
  }

  return MenuButton({ className: 'attraction-menu-button', actions });
};
