import { faCar, faGlobe, faMap, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { getLocationCity } from "trip-util";
import { ChatRecord } from "../../../page/TripChat";

export interface BubbleResult {
  text: string;
  bubbles: ChatRecord[];
}
export type BubbleProcessor = (message: string, submit: (q: string) => void) => BubbleResult;

interface ChatBubbleProps {
  label: string;
  from?: string;
  onAdd: () => void;
}

export function ChatBubble(props: ChatBubbleProps) {
  const { label, from, onAdd } = props;
  const city = getLocationCity(label);
  return <div className='chat-bubble button-container'>
    {city}
    <Tooltip title='Search Web'>
      <Link to={`https://www.google.com/search?q=${encodeURIComponent(label)}+attractions`} target="_blank">
        <FontAwesomeIcon icon={faGlobe} className="bubble-icon" />
      </Link>
    </Tooltip>
    <Tooltip title='Map'>
      <Link to={`https://www.google.com/maps/?q=${encodeURIComponent(label)}`} target="_blank">
        <FontAwesomeIcon icon={faMap} className="bubble-icon" />
      </Link>
    </Tooltip>
    {from ?
      <Tooltip title='Direction'>
        <Link to={`https://www.rome2rio.com/s/${encodeURIComponent(from)}/${encodeURIComponent(label)}`} target="_blank">
          <FontAwesomeIcon icon={faCar} className="bubble-icon" />
        </Link>
      </Tooltip> : null
    }
    <Tooltip title='Add Day Trip'>
      <FontAwesomeIcon icon={faPlus} className="bubble-icon" onClick={onAdd} />
    </Tooltip>
  </div>;
}

