import TypingEffect from "../../widget/TypingEffect";
import "./QuestionSample.scss";
import React, { useEffect, useState } from "react";

export function QuestionSample() {
  const [showHint, setShowHint] = useState<boolean>(false);
  const questions = [
    '12 days in Japan',
    `I want to spend 12 to 14 days in Portugal and Spain. I'm interested in historical sites, hiking, and local food.`,
    `10 day trip to London, then renting a car and taking a road trip to towns in Cotswolds.`
  ];

  useEffect(() => {
    const today: string = new Date().toDateString();
    const lastShownDate: string | null = localStorage.getItem("hintShownDate");

    // show hint only once per day
    if(lastShownDate !== today) {
      setShowHint(true);
      localStorage.setItem("hintShownDate", today); 
    }
  }, []);

  const handleCloseHint = (): void => {
    setShowHint(false);
  };

  return (
    <>
      {showHint && (
        <div className="sample-div">
          <TypingEffect texts={questions} loop={true} />
        </div>
      )}
    </>
  );
};
