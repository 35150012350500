import { Trip, TripDay } from "@shared/types/itinerary";
import { Plan } from "@shared/types/plan";
import { createPlanDayFromTrip } from "./planConvertion";

export function syncPlanDay(dayIndex: number, trip: Trip, plan: Plan) {
  const day = trip.itinerary?.[dayIndex];

  if(day) {
    plan.days[plan.itineraryOffset + dayIndex] = createPlanDayFromTrip(day, trip, dayIndex, plan);
  }
}

export function syncDeleteDay(dayIndex: number, plan: Plan) {
  plan.days.splice(plan.itineraryOffset + dayIndex, 1);
  plan.itineraryLength--;
  updateItineraryDayIndex(plan);
}

function updateItineraryDayIndex(plan: Plan) {
  for(let i = 0; i < plan.itineraryLength; i++) {
    plan.days[i + plan.itineraryOffset].itineraryDay = i;
  }
}

export function syncAddDay(dayIndex: number, ndays: number, trip: Trip, plan: Plan) {
  for(let i = 0; i < ndays; i++) {
    const planDay = createPlanDayFromTrip(trip.itinerary![dayIndex + i + 1], trip, i + dayIndex + 1, plan);
    plan.days.splice(i + plan.itineraryOffset + dayIndex + 1, 0, planDay);
  }

  plan.itineraryLength += ndays;
  updateItineraryDayIndex(plan);
}