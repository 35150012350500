import { StoryDay } from '@shared/types/chat';
import ImageCarousel, { ImageInfo } from '../../widget/ImageCarousel';
import './OutlineItem.scss';
import { Chip } from '@mui/material';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAttractionIcon } from '../chat/AttractionIcon';
import { AttractionInfoIcon } from '../chat/AttractionInfoIcon';
import { getImagePlace, getLocationCity } from 'trip-util';
import { CityGuideView } from '../guide/CityGuideView';

interface OutlineItemProps {
  day: StoryDay;
}

function OutlineItem0(props: OutlineItemProps) {
  const { day } = props;
  const [guideCity, setGuideCity] = useState<string>();

  function getImages(): ImageInfo[] {
    return day.places?.map(p => ({
      url: `/image?place=${getImagePlace(p.place, p.location)}`,
      tooltip: p.place
    }));
  }

  function getTitle(): any {
    return <div className='outline-item-title'>
      {day.locations.map((loc, idx) =>
        <div className='outline-title-part' key={idx}>
          <div key={idx} onClick={() => setGuideCity(loc)} className='outline-city'>
            {idx > 0 ? '/' : ''}
            {getLocationCity(loc)}
          </div>
        </div>
      )}
      &nbsp;
      {day.overnightCity !== day.locations[0] ?
        <div onClick={() => setGuideCity(day.overnightCity)} className='outline-city'>
          ({getLocationCity(day.overnightCity)})
        </div> : null
      }
    </div>
  }

  return (
    <div className='outline-item-container'>
      <ImageCarousel images={getImages()} className='image-carousel' />
      <div className='outline-item-contents'>
        <div className='outline-day'>
          <Chip label={`Day ${day.day}`} variant='outlined' />
          <div className='outline-location'>{getTitle()}</div>
        </div>
        <div className='outline-description'>{day.description}</div>
        {day.places.map((p, idx) =>
          <div className='outline-place-container' key={idx}>
            <div className='outline-place-icon-container'>
              <FontAwesomeIcon icon={getAttractionIcon(p.typeOfAttraction || '')} className='outline-place-icon' />
            </div>
            <a href={`https://google.com/search?q=${encodeURIComponent(p.place + ',' + p.location)}`} target='_blank'
              className='outline-place'>
              {p.place}
            </a>
            <div className='outline-place-info-icon'>
              <AttractionInfoIcon attraction={p} />
            </div>
          </div>
        )}
      </div>
      <CityGuideView open={!!guideCity} location={guideCity || day.overnightCity}
        onClose={() => setGuideCity(undefined)} />
    </div>
  );
}

export const OutlineItem = React.memo(OutlineItem0);