import React, { useState, useEffect, useRef } from 'react';

interface TypingEffectProps {
  texts: string[];      // The text to animate
  speed?: number;    // Typing speed in milliseconds (optional)
  loop?: boolean;
}

const TypingEffect: React.FC<TypingEffectProps> = ({ texts, speed = 60, loop = true }) => {
  const [displayedText, setDisplayedText] = useState<string>('');
  const delay = '                    ';

  useEffect(() => {
    const texts2 = texts.map(line => `${line}${delay}`);
    let tidx = 0;
    let index = 0;
    const interval = setInterval(() => {
      const text = texts2[tidx];
      const nindex = index + 1;
      setDisplayedText((prev) => prev + text[index++]);

      if(nindex >= text.length) {
        tidx = (tidx === texts.length - 1) ? 0 : tidx + 1;

        if(!loop && tidx == 0) {
          clearInterval(interval);
          setDisplayedText('');
          return;
        }

        index = 0;
        setDisplayedText(texts[tidx][0]);
      }
    }, speed);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [texts, speed]);

  return <p>{displayedText}</p>;
};

export default TypingEffect;
