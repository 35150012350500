import React, { useEffect, useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch,
  FormControlLabel, Snackbar
} from '@mui/material';
import axios from 'axios';
import "./ShareDialog.scss"
import { TripAccess } from '@shared/types/UserTrip';

interface ShareDialogProps {
  tripId?: string;
  open: boolean,
  publicAccess: TripAccess,
  onClose?: () => void,
  onShare?: (publicAccess: TripAccess) => void;
};

export function ShareDialog(props: ShareDialogProps) {
  const { tripId, publicAccess, open, onClose, onShare } = props;
  const [ticket, setTicket] = useState<string>("");
  const [editable, setEditable] = useState<boolean>(false);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    setIsPublic(!!publicAccess);
    setEditable(publicAccess === 'edit');
  }, [publicAccess]);

  const handleClose = () => {
    onClose && onClose();
  };

  const submit = () => {
    axios.post<{ ticket: string }>("/share", {
      _id: tripId,
      editable: editable,
      isPublic: isPublic,
    }).then(result => {
      if(result.data.ticket) {
        let link = `${window.location.href.replace('/open/', '/outline/')}`;

        if(isPublic) {
          onShare && onShare(isPublic ? (editable ? 'edit' : 'read') : undefined);
        }
        else {
          link += `${link.includes('?') ? '&' : '?'}ticket=${result.data.ticket}`;
        }

        setTicket(link);
        navigator?.clipboard.writeText(link);
        setMessage('Link copied to clipboard. Paste it in your email or message to share with your friends.')
      }
      else {
        setMessage('Failed to generate link.');
      }
    }).catch(ex => {
      setMessage(`Failed to generate link: ${ex}`);
    })
  };

  return (
    <Dialog open={open} onClose={handleClose} className='share-dialog'>
      <DialogTitle>Share Itinerary</DialogTitle>
      <DialogContent>
        <div className='content-container'>
          <div className='ticket-text'>{ticket}</div>
          <FormControlLabel label="Public Access"
            control={<Switch checked={isPublic} onChange={() => setIsPublic(!isPublic)} />} />
          <FormControlLabel label="Enable Edit"
            control={<Switch checked={editable} onChange={() => setEditable(!editable)} />} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant='contained'>Close</Button>
        <Button onClick={submit} color="primary" variant='contained'>
          {isPublic ? 'Submit' : 'Generate Link'}
        </Button>
      </DialogActions>
      <Snackbar open={!!message} message={message} autoHideDuration={10000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => setMessage("")}></Snackbar>
    </Dialog>
  );
};

export default ShareDialog;
