import { Location } from "react-router-dom";

// return a new state with current url pushed to returnUrls

export function pushReturnUrl(location: Location): any {
  const state = location.state || {};
  const returnUrls = (state.returnUrls ?? []).concat([]);
  returnUrls.push(`${location.pathname}${location.search}`);
  return {
    ...state,
    returnUrls: returnUrls
  };
}// get the returnUrl from state and the state to use for when going back to returnUrl

export function popReturnUrl(location: Location): { returnUrl: string; returnState: any; } {
  const returnUrls: string[] = (location.state?.returnUrls ?? []).concat([]);
  const returnUrl = returnUrls.pop() || '';
  return {
    returnUrl: returnUrl,
    returnState: {
      returnUrls: returnUrls
    }
  };
}

