import React, { useEffect, useState } from 'react';
import { Paper, SxProps } from "@mui/material";
import { LocationInfo, Trip, TripDay } from "../../../../shared/types/itinerary";
import { TripDayComponent } from './TripDayComponent';
import './TripComponent.scss';
import {
  DragDropContext, Droppable, Draggable, DropResult, DroppableProps, DroppableProvided, DraggableProvided
} from 'react-beautiful-dnd';
import { ViewMode } from '../../page/TripChat';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as CryptoJS from 'crypto-js';

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if(!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

interface TripComponentProps {
  trip: Trip;
  style: SxProps;
  viewMode?: ViewMode;
  editable: boolean;
  selectedDays: Set<number>;
  onSelect: (days: Set<number>) => void;
  onChange: (trip: Trip, instruction?: string) => void;
  onDayChange: (trip: Trip, idx: number) => void;
  onAdd: (day: TripDay) => void;
  onDelete: (day: TripDay) => void;
}

interface DraggableDay extends TripDay {
  key: string;
}

function TripComponent0(props: TripComponentProps) {
  const { trip, style, viewMode, editable, onSelect, onChange, onDayChange, onAdd, onDelete } = props;
  const [selectedDays, setSelectedDays] = useState<Set<number>>(props.selectedDays);
  const [days, setDays] = useState<DraggableDay[]>([]);
  let attractionNumber = 0;

  useEffect(() => {
    setDays(trip?.itinerary?.map(i => ({ ...i, key: `day${i.day}` })) || []);
  }, [trip]);

  useEffect(() => {
    setSelectedDays(props.selectedDays);
  }, [props.selectedDays]);

  function clicked(event: React.MouseEvent, index: number) {
    let days = new Set(selectedDays);

    if(days.has(index)) {
      if(event.altKey) {
        days.clear();
      }
      else {
        days.delete(index);
      }
    }
    else if(trip.itinerary) {
      if(event.altKey) {
        days = new Set(Array(trip.itinerary.length).keys())
      }
      else if(event.ctrlKey) {
        days.add(index);
      }
      else {
        days = new Set([index]);
      }
    }

    setSelectedDays(days);
    onSelect(days);
  }

  function deleteDay(index: number) {
    const deleted = days.splice(index, 1);
    setDaysAndTrip(days);
    onDelete(deleted[0]);

    if(selectedDays.has(index)) {
      selectedDays.delete(index);
      setSelectedDays(selectedDays);
    }
  }

  function deleteCity(index: number) {
    let lastIdx = index;
    const city = days[index].overnightCity;

    for(; lastIdx < days.length && days[lastIdx].overnightCity === city; lastIdx++) {
    }

    for(lastIdx--; days[lastIdx].overnightCity === city; lastIdx--) {
      days.splice(lastIdx, 1);
      selectedDays.delete(lastIdx);
    }

    setDaysAndTrip(days, `Stays at ${city} starting day ${index + 1} have been removed.`);
    setSelectedDays(selectedDays);
  }

  function changeDay(index: number, day: TripDay, instruction?: string) {
    days[index] = { ...day, key: `` };
    setDaysAndTrip(days, instruction, index);
  }

  const onDragEnd = (result: DropResult) => {
    if(!result.destination || !days) return;

    const newItems = Array.from(days);
    const sourceIdx = result.source.index;
    const toIdx = result.destination.index;
    let n = 1;

    // the source is the first day of a city, move all days in the same city together.
    if(sourceIdx === 0 || days[sourceIdx].overnightCity !== days[sourceIdx - 1].overnightCity) {
      for(let i = sourceIdx + 1; i < days.length; i++) {
        if(days[i].overnightCity === days[sourceIdx].overnightCity) {
          n++;
        }
        else {
          break;
        }
      }
    }

    if(toIdx > sourceIdx && toIdx < sourceIdx + n) {
      n = 1;
    }

    const reorderedItems = newItems.splice(sourceIdx, n);
    newItems.splice(toIdx, 0, ...reorderedItems);

    // day trip, change overnight to previous day
    if(reorderedItems[0]?.locations[0]?.location !== reorderedItems[0]?.overnightCity &&
      // if moving one day trip to another day with the same overnight, don't change the day trip overnight
      (n == 1 && reorderedItems[0]?.overnightCity !== days[toIdx].overnightCity ||
        // don't change overnight if the day trip is staying at the same city as the subsequent days
        // e.g. city1 (city2), city2
        // in this case move the two days as is without changing the overnight
        n > 1 && reorderedItems[0]?.overnightCity !== reorderedItems[1]?.overnightCity)) {
      const overnightCity = toIdx > 0 ? newItems[toIdx - 1].overnightCity : reorderedItems[0]?.locations[0]?.location;
      reorderedItems.forEach(d => d.overnightCity = overnightCity);
    }

    setDaysAndTrip(newItems);
  }

  function onMoveAttraction(fromDay: number, fromAttraction: number, toDay: number, toAttraction: number) {
    const ndays = days.map(d => ({
      ...d,
      attractions: d.attractions.map(a => ({
        ...a
      }))
    }));
    const [attraction] = ndays[fromDay].attractions.splice(fromAttraction, 1);
    const adj = fromDay === toDay && fromAttraction < toAttraction ? -1 : 0;
    ndays[toDay].attractions.splice(toAttraction + adj, 0, attraction);

    const allLocations = ndays[fromDay].locations.concat(ndays[toDay].locations);
    // update the location list in from and to day (it may have changed)
    setDayLocations(ndays[fromDay], allLocations);
    setDayLocations(ndays[toDay], allLocations);

    setDaysAndTrip(ndays);
  }

  // set the TripDay.locations to match the locations in attractions.
  function setDayLocations(day: TripDay, allLocations: LocationInfo[]) {
    const locations = Array.from(new Set(day.attractions.map(a => a.location)));
    day.locations = locations.map(loc => allLocations.find(a => a.location === loc)).filter(a => !!a) as LocationInfo[];
  }

  function setDaysAndTrip(newItems: DraggableDay[], instruction = '', dayIndex = -1) {
    setDays(newItems);
    const ntrip = {
      ...trip,
      days: newItems.length,
      itinerary: newItems.map((i, index) => {
        const { key, ...day } = i;
        return { ...day, day: index + 1 };
      })
    };

    if(dayIndex >= 0) {
      onDayChange(ntrip, dayIndex);
    }
    else {
      onChange(ntrip, instruction);
    }
  };

  function getDayList(provided?: DroppableProvided): any {
    attractionNumber = 0;

    return (
      <DndProvider backend={HTML5Backend}>
        <div {...provided?.droppableProps} ref={provided?.innerRef} className='list'>
          {days.map((day, index) => (
            viewMode === 'dayOverview' ?
              <Draggable key={day.key} draggableId={day.key} index={index}>
                {(provided) => getDayItem(day, index, provided)}
              </Draggable> :
              getDayItem(day, index)
          ))}
          {provided?.placeholder}
        </div>
      </DndProvider>
    );
  }

  function getDayItem(day: DraggableDay, index: number, provided?: DraggableProvided) {
    const selected = selectedDays.has(index);
    // the onMoveAttraction doesn't see to reflect the new days after multiple dnd of attractions, even though
    // it should according to documentation. use a hash in the key to force the nested TripDayComponent to be
    // updated with the new properties.
    const dayHash = CryptoJS.SHA256(JSON.stringify(days)).toString();
    const div = (
      <div ref={provided?.innerRef} className='list-item' key={`${index}-${dayHash}`}
        {...provided?.draggableProps}
        {...provided?.dragHandleProps}>
        <TripDayComponent day={day} selected={selected} viewMode={viewMode} editable={editable}
          attractionNumber={selected ? attractionNumber : undefined}
          onSelect={(event) => clicked(event, index)}
          onDelete={() => deleteDay(index)}
          onDeleteCity={() => deleteCity(index)}
          onAdd={onAdd}
          onChange={(day, instruction) => changeDay(index, day, instruction)}
          onMoveAttraction={onMoveAttraction}
          trip={trip}
          prevStay={index > 0 ? trip?.itinerary?.[index - 1]?.overnightCity : undefined} />
      </div>
    );

    if(selected) {
      attractionNumber += day.attractions.length;
    }

    return div;
  }

  return (
    <Paper sx={{ ...style }} className='trip-container'>
      <DragDropContext onDragEnd={onDragEnd}>
        {viewMode === 'dayOverview' ?
          <StrictModeDroppable droppableId='droppable'>
            {getDayList}
          </StrictModeDroppable> :
          getDayList()
        }
      </DragDropContext>
    </Paper>
  );
}

export const TripComponent = React.memo(TripComponent0);
