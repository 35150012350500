import { useState } from "react";
import ConfirmDialog from "../../widget/ConfirmDialog";

export type ConfirmType = 'unsaved' | 'plan-change' | 'idle-chat' | '';

interface TripChatConfirmDialogProps {
  confirmType: ConfirmType;
  onSubmit: (option: string) => void;
}

export function TripChatConfirmDialog(props: TripChatConfirmDialogProps) {
  const { confirmType, onSubmit: closeConfirmed } = props;
  const [message, setMessage] = useState<string>('');

  function getConfirmMessage(): string {
    switch(confirmType) {
      case 'unsaved':
        return 'You have unsaved changes, discard changes?'
      case 'plan-change':
        return 'Plan has been modified. Refreshing will cause your changes to be lost. Proceed?';
      case 'idle-chat':
        return `Seems you have finished exploring. Switch to 'Plan' to make detailed plan?`;
    }

    return "Proceed?";
  }

  return <ConfirmDialog open={!!confirmType} label={getConfirmMessage()}
        buttons={['No', 'Yes']} onSubmit={closeConfirmed} />
}