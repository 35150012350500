import { useState, MouseEvent, useEffect, useRef } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import "./MenuButton.scss";
import { ListItemIcon, Tooltip } from '@mui/material';

interface MenuAction {
  label: string;
  icon: any;
  disabled?: boolean;
  action: () => void;
}

interface MenuButtonProps {
  className?: string;
  tooltip?: string;
  actions: MenuAction[];
}

export function MenuButton(props: MenuButtonProps) {
  const { className, tooltip, actions } = props;
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [minWidth, setMinWidth] = useState<number>(0);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const handleMouseUp = (event: any) => handleClose();
    window.addEventListener('mouseup', handleMouseUp);

    if(divRef.current) {
      setMinWidth(divRef.current.offsetHeight);
    }

    return () => window.removeEventListener('mouseup', handleMouseUp);
  }, []);

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function itemClicked(event: MouseEvent, action: () => void) {
    event.stopPropagation();
    action();
    handleClose();
  };

  return (
    <Tooltip title={tooltip} placement='top'>
      <div className={`event-menu-button-container ${className}`} ref={divRef}>
        <div className='menu-button-icon-container' onClick={handleClick} 
          style={{ minWidth: `${minWidth}px` }}>
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </div>
        <Menu id="menu-basic" anchorEl={anchorEl} open={open} onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'menu-basic-button',
          }} >
          {actions.map(item =>
            <MenuItem onClick={(event) => itemClicked(event, item.action)} className='event-menu-item'
              disabled={item.disabled} key={item.label}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              {item.label}
            </MenuItem>
          )
          }
        </Menu>
      </div>
    </Tooltip>
  );
};
