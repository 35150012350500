import { useEffect, useState } from 'react';
import "./CityGuide.scss";
import { Attraction } from '@shared/types/itinerary';
import { getCityInfo, getTopAttractions } from '../../service/chatRequests';
import LoadingScreen from '../../widget/LoadingScreen';
import { GuideAttraction } from './GuideAttraction';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { CityInfo } from '@shared/types/city';

interface CityGuideProps {
  location: string;
};

export function CityGuide(props: CityGuideProps) {
  const { location } = props;
  const [attractions, setAttractions] = useState<Attraction[]>([]);
  const [cityInfo, setCityInfo] = useState<CityInfo>();

  useEffect(() => {
    getTopAttractions(location, 20).then(arr => setAttractions(arr));
    getCityInfo(location).then(info => setCityInfo(info));
  }, []);

  const loading = attractions.length == 0;

  return (
    <div className='city-guide-content'>
      <Link to={`https://google.com/search?q=${encodeURIComponent(location)}+Attractions`} target='_blank'>
        <Typography variant='h5' align='center' className='city-title'>{location}</Typography>
      </Link>
      <div className='city-info-container'>
        <div className='city-description'>
          {cityInfo?.description}
        </div>
        <div className='city-transportation'>
          <div className='text-label'>
            Transportation
          </div>
          {cityInfo?.transportation}
        </div>
        <div className='city-airport'>
          <div className='text-label'>
            Airport
          </div>
          {cityInfo?.airport}
        </div>
      </div>
      <div className='attractions-container'>
        <div className='text-label attractions-title'>Top Attractions</div>
        {attractions.map((attraction, idx) =>
          <GuideAttraction attraction={attraction} key={idx} rank={idx + 1} />
        )}
      </div>
      {loading ?
        <LoadingScreen>
          <Typography variant='h5' textAlign={'center'} color='textSecondary'>Researching {location}</Typography>
        </LoadingScreen> : null
      }
    </div>
  );
};