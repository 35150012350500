import { List, Stack, Chip, Divider, Tooltip, Typography } from "@mui/material";
import { Trip, TripDay } from "../../../../shared/types/itinerary";
import { AttractionComponent } from './AttractionComponent';
import "./TripDayComponent.scss";
import {
  faMagnifyingGlassMinus, faMagnifyingGlassPlus, faTrain
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { getLocationCity, getTripDayTitle } from "trip-util";
import { TripDayMenuButton } from "./TripDayMenuButton";
import DayDialog from "../dialog/DayDialog";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import CustomTooltip from "../../widget/CustomTooltip";
import { ViewMode } from "../../page/TripChat";
import OvernightDialog from "../dialog/OvernightDialog";
import { AttractionsDialog } from "../dialog/AttractionsDialog";
import { CityGuideView } from "../guide/CityGuideView";

interface TripDayComponentProps {
  day: TripDay;
  trip: Trip;
  selected?: boolean;
  prevStay?: string;
  viewMode?: ViewMode;
  editable: boolean;
  attractionNumber?: number;
  onSelect: (event: React.MouseEvent) => void;
  onDelete: () => void;
  onDeleteCity: () => void;
  onAdd: (day: TripDay) => void;
  onChange: (day: TripDay, instruction?: string) => void;
  onMoveAttraction: (fromDay: number, fromAttraction: number, toDay: number, toAttraction: number) => void;
}

function TripDayComponent0(props: TripDayComponentProps) {
  const { day, trip, selected, prevStay, viewMode, editable, attractionNumber, onSelect, onDelete, onDeleteCity,
    onAdd, onChange, onMoveAttraction } = props;
  const [editDay, setEditDay] = useState<TripDay>();
  const [overnightDay, setOvernightDay] = useState<TripDay>();
  const [attractionDay, setAttractionDay] = useState<TripDay>();
  const [guideCity, setGuideCity] = useState<string>();

  function getZoomLabel() {
    return <div style={{ whiteSpace: 'pre-line' }}>
      Zoom {!selected ? 'into' : 'out of'} Day {day.day}.
      {selected ? '\nAlt-Click to clear all.' : '\nAlt-Click to select all. Ctrl-Click to select more.'}
    </div>;
  }

  function getOvernightCity(day: TripDay): string {
    const title = getTripDayTitle(day);
    const p1 = title.indexOf('(');
    const p2 = title.indexOf(')', p1 + 1);

    return p2 > p1 && p1 > 0 ? title.substring(p1 + 1, p2) : '';
  }

  function deleteAttraction(index: number) {
    const deleted = day.attractions.splice(index, 1);
    const attractionLocs = day.attractions.map(a => a.location);
    day.locations = day.locations.filter(loc => attractionLocs.includes(loc.location));
    onChange(day, `Remove ${deleted[0].place} from day ${day.day}`);
  }

  function closeOvernightDialog(ok: boolean) {
    setOvernightDay(undefined);

    if(ok) {
      onChange(day);
    }
  }

  function attractionChanged(day: TripDay) {
    onChange(day);
    setAttractionDay(undefined);
  }

  const tooltip = day.notes ?
    <Typography fontSize={12} style={{ whiteSpace: 'pre-line' }}>{day.notes}</Typography>
    : null;

  function editOvernight(day: TripDay): void {
    setOvernightDay(day);
  }

  // return from/to
  function getTransportationLocations(): string {
    const dayTrip = day.locations.some(loc => day.overnightCity !== loc.location);
    return dayTrip
      ? `${day.overnightCity}/${day.locations.map(loc => loc.location).find(loc => loc !== day.overnightCity)}`
      : `${prevStay}/${day.overnightCity}`;
  }

  return (
    <Stack className={`trip-day-container ${selected ? 'selected' : ''} ${viewMode === 'dayOverview' ? 'draggable' : ''}`}>
      <div className="title-container">
        <div className="day-label">
          <Chip label={'Day ' + day.day} variant='filled' sx={{ fontWeight: "bold" }} />
        </div>
        <div className="title-label-div">
          <CustomTooltip title={tooltip}>
            <div className="title-label">
              {
                day.locations.map((loc, idx) => (
                  <div className="tripday-location" key={idx} onClick={() => setGuideCity(loc.location)}>
                    {idx > 0 ? '/' : ''}{getLocationCity(loc.location)}
                  </div>
                ))
              }
              {
                getOvernightCity(day) ?
                  <div className="tripday-location" onClick={() => setGuideCity(day.overnightCity)}>
                    ({getOvernightCity(day)})
                  </div> : null
              }
              {day.notes ?
                <FontAwesomeIcon icon={faCommentDots} className="notes-icon" onClick={() => setEditDay(day)} /> : null}
            </div>
          </CustomTooltip>
        </div>
        <div className="icon-container">
          {prevStay && prevStay !== day.locations[0]?.location || day.transportation ?
            <CustomTooltip title={day.transportation} disableInteractive className="button-icon">
              <a href={prevStay ? `https://www.rome2rio.com/s/${getTransportationLocations()}` : ""}
                target="_blank" >
                <FontAwesomeIcon icon={faTrain} size="lg" className="transportation-icon" />
              </a>
            </CustomTooltip> : null
          }
          <Tooltip title={getZoomLabel()} className="button-icon" disableInteractive
            onClick={(event) => onSelect(event)}>
            {selected ? <FontAwesomeIcon icon={faMagnifyingGlassMinus} size="lg" />
              : <FontAwesomeIcon icon={faMagnifyingGlassPlus} size="lg" />}
          </Tooltip>
          <TripDayMenuButton disabled={!editable} overnightCity={day.overnightCity}
            onAdd={() => onAdd(day)}
            onDelete={onDelete}
            onDeleteCity={onDeleteCity}
            onOvernight={() => editOvernight(day)}
            onEdit={() => setEditDay(day)}
            onEditAttractions={() => setAttractionDay(day)}
          />

        </div>
      </div>
      {viewMode === 'dayOverview' && !selected ? null :
        <List>
          {day.attractions.map((activity, index) => (
            <div key={index}>
              <AttractionComponent activity={activity} multiLocation={day.locations.length > 1}
                compact={viewMode === 'compact'} day={day} attractionIndex={index} editable={editable}
                attractionNumber={attractionNumber !== undefined ? attractionNumber + index : undefined}
                onDelete={() => deleteAttraction(index)}
                onDropAttraction={(fromDay, fromAttr, before) =>
                  onMoveAttraction(fromDay, fromAttr, day.day - 1, index + (before ? 0 : 1))}
              />
              {index < day.attractions.length - 1 && viewMode === 'detail' ?
                <Divider sx={{ marginBottom: 1 }} /> : null
              }
            </div>
          ))}
        </List>
      }
      <DayDialog open={!!editDay} day={day} onClose={() => setEditDay(undefined)}
        onSubmit={(notes, transportation) => onChange({ ...day, notes, transportation })} />
      <OvernightDialog open={!!overnightDay} day={day} prevStay={prevStay} trip={trip}
        onClose={closeOvernightDialog} />
      <AttractionsDialog open={!!attractionDay} day={day} trip={trip} onClose={(ok) => attractionChanged(day)} />
      <CityGuideView open={!!guideCity} location={guideCity || day.overnightCity}
        onClose={() => setGuideCity(undefined)} />
    </Stack>
  );
}

export const TripDayComponent = React.memo(TripDayComponent0);
