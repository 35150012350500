import { getLocationCity } from "trip-util";
import { ChatRecord } from "../../../page/TripChat";
import { BubbleProcessor, BubbleResult, ChatBubble } from "./ChatBubble";

export function createDayTripBubble(submit: (q: string, processor: BubbleProcessor) => void): ChatRecord {
  const onclick = () => submit(`\`\`\`yaml
question: Are there day trips from the cities in the itinerary that you can recommend?
instruction: |
  1. Don't include the cities in the current itinerary as day trip destinations. 
  2. Return as many as possible, and up to 3 day trip destinations for each city.
  3. Return in markdown instead of yaml. 
  4. Preserve colons, in the following markdown format:
  From **city,country**:
    - Day trip to **city,country**: description of the day trip in about 100 words. Also include information about distance and travel time.
    - ...repeat for each day trip cities from the same city
  ... repeat for each city in the itinerary
\`\`\``, processDayTripResponse);
  return {
    message: < div className='chat-bubble' key={0} onClick={onclick} > Find Day Trips</div >,
    textType: 'bubble',
    recurring: true,
    label: 'Find Day Trips'
  };
}

const processDayTripResponse = (message: string, submit: (q: string) => void): BubbleResult => {
  const { text, dayTrips } = extractDayTrips(message);

  if(dayTrips.length) {
    // css styles defined in ChatHistory.scss

    const rows = dayTrips.map((dayTrip, didx) => (
      <div className="chat-bubble-container-row" key={didx}>
        <div className="chat-bubble-label"><div className="from-city">{getLocationCity(dayTrip.from)}</div> to </div>
        {dayTrip.dayTrips.map((to, idx) => (
          <ChatBubble from={dayTrip.from} label={to} key={idx}
            onAdd={() => submit(`Add a day trip from ${dayTrip.from} to ${to}.`)} />
        ))}
      </div>
    ));
    return {
      text: text,
      bubbles: rows.map(row => ({
        textType: 'bubble',
        message: [row]
      }))
    };
  }

  return {
    text: text,
    bubbles: []
  };
};

interface DayTrip {
  from: string;
  dayTrips: string[]
}

// extract information from markdown:
//1. **From Riyadh, Saudi Arabia:**
//   - Day Trip to Edge of the World: ...
//   - Day Trip to Al Ula: ...
function extractDayTrips(markdown: string): { text: string, dayTrips: DayTrip[] } {
  const result: DayTrip[] = [];
  const lines = markdown.split('\n');
  let fromCity;
  let dayTrips: string[] = [];
  let text = '';

  for(let line of lines) {
    const line2 = line.replaceAll('**', '').trim();
    // Pattern to match 'From' cities
    const fromPattern = / *From:? ([^:]+):$/ig;
    const fromMatch = fromPattern.exec(line2);
    if(fromMatch !== null) {
      if(fromCity && dayTrips.length) {
        result.push({ from: fromCity, dayTrips });
      }

      fromCity = fromMatch[1]; // Extract 'From' city
      dayTrips = [];
      line = line.replace(fromCity, getLocationCity(fromCity)!); // only show city name in message
      text = `${text}\n${line}`;
      continue;
    }

    if(fromCity) {
      // Pattern to match 'Day trip to' cities
      // - Day trip to city: ...
      // 1. city:
      // - Day trip to city$
      const dayTripPatterns = [
        /^[^:]* Day trip to ([^:]+):/ig,
        /^\s*\d+\. ([^:]+):/ig,
        /^[^:a-zA-Z]* Day trip to ([^:]+)$/ig,
        /^\s*- ([^:]+):/ig,
        /^\s*- ([^:]+)$/ig,
      ];
      const dayTripMatch = dayTripPatterns.map(pattern => pattern.exec(line2)).filter(m => !!m)[0];

      if(dayTripMatch) {
        const city = dayTripMatch[1]; // Extract 'Day Trip to' city
        dayTrips.push(city);
        line = line.replace(city, getLocationCity(city)!); // only show city name in message
      }
    }

    text = `${text}\n${line}`;
  }

  if(fromCity && dayTrips.length) {
    result.push({ from: fromCity, dayTrips });
  }

  return {
    text: text.trim(),
    dayTrips: result
  };
}
