import { TripSummary } from "@shared/types/summary";

// spread out the trips so trips of same country is not close to each other.
export function getDistributedCountryTrips(trips: TripSummary[]): TripSummary[] {
  let list: TripSummary[] = [];

  while(trips.length > 0) {
    list = list.concat(getDistributedDestinations(trips));
  }

  return list;
}

// extract a list of trips with only unique destinations or at minDist from the last occurance. 
// remove the extracted trips from the original list.
function getDistributedDestinations(trips: TripSummary[], minDist = 20): TripSummary[] {
  const list: TripSummary[] = [];
  const countryPosition = new Map<string, number>();

  for(let i = 0; i < trips.length; i++) {
    const countries = trips[i].destinations.split(',').map(c => c.trim());
    const pos = Math.min(...countries.map(c => countryPosition.get(c) || -1))

    if(pos < 0 || pos < i - minDist) {
      countries.forEach(c => countryPosition.set(c, list.length));
      list.push(trips[i]);
      trips.splice(i--, 1);
    }
  }

  return list;
}