import React, { useEffect, useRef, useState } from 'react';
import { IconButton, Paper, Tooltip } from "@mui/material";
import { ChatRecord } from '../../page/TripChat';
import ReactMarkDown from 'react-markdown';
import ExpandIcon from '@mui/icons-material/Expand';
import CompressIcon from '@mui/icons-material/Compress';
import QuizIcon from '@mui/icons-material/Quiz';
import "./ChatHistory.scss";
import { QuestionSample } from './QuestionSample';
import { combineElementMessages } from './bubble/bubble-util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGear } from '@fortawesome/free-solid-svg-icons';

interface ChartHistoryProps {
  records: ChatRecord[];
  showSample?: boolean;
  addRecords: (records: ChatRecord[]) => void;
}

export function ChatHistory(props: ChartHistoryProps) {
  const { records, showSample, addRecords } = props;
  const [expanded, setExpanded] = useState<boolean>(localStorage.getItem('tripx.expanded') === 'true');
  const [bubbles, setBubbles] = useState<ChatRecord[]>([]);
  const lastAnswerLines = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const divRefs = useRef<(HTMLDivElement | null)[]>([]);

  scrollToTopOfText();

  useEffect(() => {
    scrollToTopOfText();
    // collect bubbles in records
    setBubbles(bubbles.concat(
      records.filter(r => r.textType === 'bubble' && r.recurring && !bubbles.find(b => b.label === r.label))));
  }, [records]);

  function showBubbleMenu(): boolean {
    return records[records.length - 1]?.textType !== 'bubble' && bubbles.length > 0;
  }

  function computeMargin(div: HTMLDivElement): number {
    const ps: HTMLCollection = div.children;
    let h = 0;

    for(let i = 0; i < ps.length; i++) {
      const style = window.getComputedStyle(ps[i]);
      // only add the top margin since the bottom margin will be collapsed into the next div
      h += parseFloat(style.marginTop);
    }

    return h;
  }

  function scrollToTopOfText() {
    const container = containerRef.current;

    if(divRefs.current.length > 1 && container) {
      const divs = divRefs.current;
      // ignore the last two text so the scrollTop is at the last question.
      const ignore = lastAnswerLines.current + 1;
      const txtHeight = Array.from(divs).slice(0, -ignore)
        .map(d => {
          return d ? (d.offsetHeight + computeMargin(d)) : 0;
        })
        .reduce((a, b) => a + b, 0);
      container.scrollTop = txtHeight;
    }
  }

  function getChartTexts(): any[] {
    let lines: any[] = [];
    let i = 0;

    for(let record of combineElementMessages(records)) {
      const elems = getChatText(record, i);
      lines = lines.concat(elems);
      i += elems.length;
      lastAnswerLines.current = elems.length;
    }

    return lines;
  }

  function getChatText(record: ChatRecord, i: number): any[] {
    const ai = record.textType === 'ai';

    if(typeof record.message === 'string') {
      const user = record.textType === 'user';
      const className = user ? 'user-text' : ai ? 'ai-text' : '';
      return record.message.split(user ? '\n' : '-no-split-for-ai-').map((line, k) =>
        <div key={`${i + k}`} ref={(el) => divRefs.current[i + k] = el} className={`question-answer ${className}`}>
          {ai ? <FontAwesomeIcon icon={faUserGear}/> : null}
          <ReactMarkDown className={className}>{line}</ReactMarkDown>
        </div >
      );
    }

    return [<div className='chat-bubble-container' key={i}>
      {[record.message].flat()}
    </div>];
  }

  function toggleExpand() {
    setExpanded(!expanded);
    localStorage.setItem('tripx.expanded', `${!expanded}`);
  }

  function showBubbles() {
    addRecords(bubbles);
  }

  return <div className='history-outer-container'>
    <Paper className={`history-container ${expanded ? 'expanded' : ''}`} ref={containerRef}>
      {getChartTexts()}
      {showSample ?
        <QuestionSample /> : null
      }
    </Paper>
    {showBubbleMenu() ?
      <Tooltip title='Show Prompts'>
        <IconButton className='prompt-icon fixed-icon' onClick={showBubbles}>
          <QuizIcon />
        </IconButton>
      </Tooltip> : null
    }
    <Tooltip title={expanded ? 'Shrink' : 'Expand'}>
      <IconButton className='expand-icon fixed-icon' onClick={toggleExpand}>
        {expanded ? <CompressIcon /> : <ExpandIcon />}
      </IconButton>
    </Tooltip>
  </div>;
}
