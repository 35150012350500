import axios from "axios";
import { ChatResponse, Conversation, TripOutline } from "../../../shared/types/chat";
import { Attraction, Trip } from "../../../shared/types/itinerary";
import { Plan, PlanDay } from "../../../shared/types/plan";
import { TransportationInfo } from "../../../shared/types/transportation";
import { CityInfo } from "@shared/types/city";

interface SaveResult {
  insertedId?: string;
  message: string;
}

export async function ask(question: string, history: Conversation[], trip: Trip): Promise<ChatResponse> {
  const result = axios.post("/ask", { 'question': question, 'history': history, 'trip': trip })
    .then(result => result.data as ChatResponse);
  return result;
}

export async function getTransportationInfo(trip: Trip, otrip: Trip): Promise<TransportationInfo> {
  const result = axios.post("/transportation-info", { 'trip': trip, 'otrip': otrip })
    .then(result => result.data as TransportationInfo);
  return result;
}

export async function optimizeRoute(planDay: PlanDay): Promise<PlanDay> {
  const result = axios.post("/optimize-route", { 'planDay': planDay })
    .then(result => result.data as PlanDay);
  return result;
}

export async function save(tripName: string, trip: Trip, plan?: Plan, _id?: string, unsaved?: boolean)
  : Promise<SaveResult> {
  const result = axios.post(unsaved ? '/unsaved-trip' : '/save', {
    '_id': _id,
    'tripName': tripName,
    'trip': trip,
    'plan': plan
  }).then(result => result.data);
  return result;
}

export async function getTripOutline(tripId: string): Promise<TripOutline> {
  return axios.get(`/trip-outline/${tripId}`)
    .then(result => result.data as TripOutline);
}

export function getTopAttractions(location: string, n: number): Promise<Attraction[]> {
  return axios.get(`/top-attractions/${location}/${n}`).then(result => result.data as Attraction[]);
}

export function getCityInfo(location: string): Promise<CityInfo> {
  return axios.get(`/city-info/${location}`).then(result => result.data as CityInfo);
}