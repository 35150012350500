import "./CityGuideView.scss";
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CityGuide } from './CityGuide';

interface CityGuideViewProps {
  open: boolean;
  location: string;
  onClose: () => void;
};

export function CityGuideView(props: CityGuideViewProps) {
  const { open, location, onClose } = props;

  return (
    <Modal open={open} hideBackdrop>
      <div className='city-guide-container'>
        <div className="city-guide-view">
          <CityGuide location={location} />
        </div>
        <CloseIcon className='close-icon' onClick={() => onClose()} />
      </div>
    </Modal>
  );
};