import React from 'react';
import { Container, Typography, Box, Link, Divider } from '@mui/material';
import { AppToolbar } from '../component/AppToolbar';
import './AboutPage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMartiniGlassCitrus, faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons';

const AboutPage: React.FC = () => {
  return (
    <div className='about-page-container'>
      <AppToolbar current='admin' />
      <div className='about-page-content'>
        <img src="/images/joyful-traveler-posing.jpg" alt="Service Description" />
        <div className='about-page-right-content'>
          <h3 className='title1'>
            About Map<div className='n-and'>N</div>Hat Itinerary Builder
          </h3>
          <Divider />
          <Typography variant="body1" paragraph className='about-content'>
            <p>
              Welcome to Map<span className='n-and'>N</span>Hat! If you're anything like us—travel enthusiasts
              at heart—you know the thrill of exploring new places but also the *not-so-thrilling* side of planning.
              Crafting the perfect itinerary usually means hours of research, plotting routes on maps,
              juggling timelines, hunting down transportation, and finally organizing everything on paper.
              In the end, you often end up with a cluttered, hand-filled calendar or spreadsheet that
              needed constant updates.
            </p>
            <p>
              Then we tried ChatGPT, which gave us a great starting point, but the itineraries were long and hard
              to edit, and you need to try to memorize it while trying to make changes with the help of AI or
              by yourself.  So, we created Map<span className='n-and'>N</span>Hat—a tool designed to
              make itinerary planning a breeze.
            </p>
            <p>
              With Map<span className='n-and'>N</span>Hat, you get a streamlined,
              interactive experience that takes the hassle out of organizing your travel plans. You can see your
              itinerary evolving before your eyes, points of interests marked on an interactive map for you to 
              explore, and a calendar with an at-a-glance view of all your activities and notes.
            </p>
            <p>
              We hope it makes your travel adventures as fun to plan as they are to experience!
            </p>
          </Typography>

          <Divider />
          <h3 className='title1'>
            Contact Us
          </h3>
          <Typography variant="body1" textAlign={'center'}>
            Questions and Feedbacks: <Link href="mailto:info@mapnhat.com">info@mapnhat.com</Link>
          </Typography>
          <Typography className='about-footer'>
            Built with Joy in Las Vegas <FontAwesomeIcon icon={faMartiniGlassCitrus} />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
