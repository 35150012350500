import { Trip } from "@shared/types/itinerary";
import { TransportationInfo } from "@shared/types/transportation";

// get the locations visited in the day, including the previous overnight.
export function getTransportationLocations(trip: Trip, i: number): string[] {
  const alocs = trip?.itinerary?.[i].attractions
    ?.map(a => a.location) || [];

  return [ trip.itinerary![i - 1].overnightCity, alocs, trip.itinerary![i].overnightCity ]
    .flat()
    .filter((a, idx, self) => self.indexOf(a) === idx);
}

export function copyTransporationInfo(trip: Trip, info: TransportationInfo, otrip?: Trip) {
  const existingTips = getTransportationTips(otrip);
  for(let i = 1; i < (trip.itinerary?.length ?? 0); i++) {
    const locs = getTransportationLocations(trip, i);
    const existingTip = existingTips[locs.join(':')];

    if(existingTip) {
      trip.itinerary![i].transportation = existingTip;
    }
    else {
      const tips = [];

      for(let k = 1; k < locs.length; k++) {
        const from = locs[k - 1];
        const to = locs[k];

        tips.push(info.transportations.find(t => t.fromLocation === from && t.toLocation === to)?.instruction);
      }

      trip.itinerary![i].transportation = tips.filter(a => !!a).join('\n');
    }
  }
}

// [transportationLocations] -> transportation
export function getTransportationTips(trip?: Trip): Record<string, string> {
  const map: Record<string, string> = {};

  if(trip?.itinerary) {
    for(let i = 1; i < trip.itinerary?.length; i++) {
      if(trip.itinerary[i].transportation) {
        const locs = getTransportationLocations(trip, i);
        map[locs.join(':')] = trip.itinerary[i].transportation;
      }
    }
  }

  return map;
}

