import { useEffect, useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import "./AttractionsDialog.scss";
import { Attraction, Trip, TripDay } from '@shared/types/itinerary';
import { getTopAttractions } from '../../service/chatRequests';
import { AttractionItem } from './AttractionItem';
import { isSamePlace } from 'trip-util';
import LoadingScreen from '../../widget/LoadingScreen';

interface AttractionsDialogProps {
  open?: boolean;
  day: TripDay;
  trip: Trip;
  onClose?: (ok: boolean) => void;
};

export function AttractionsDialog(props: AttractionsDialogProps) {
  const { open, day, trip, onClose } = props;
  const [attractions, setAttractions] = useState<Attraction[]>([]);
  const [selectedAttractions, setSelectedAttractions] = useState<Attraction[]>([]);

  useEffect(() => {
    if(open) {
      const thisAttractions = day.attractions || [];
      const otherAttractions = trip.itinerary?.filter(d => d.day !== day.day).map(d => d.attractions).flat() || [];
      const dayLocations = day.locations.map(loc => loc.location);
      const locations = dayLocations.length ? dayLocations : [day.overnightCity];
      Promise.all(locations.map(loc => {
        const otherSameLoc = otherAttractions.filter(a => a.location === loc);
        const n = thisAttractions.length + otherSameLoc.length + 10;
        return getTopAttractions(loc, Math.max(10, n));
      })).then(arrs => {
        const othersNonexist = arrs.flat().filter(a => !thisAttractions.find(a2 => isSamePlace(a2.place, a.place)))
          // sorting on ranking (default rank = 2)
          .sort((a, b) => (a.rank ?? 2) - (b.rank ?? 2));
        const all = thisAttractions.concat(othersNonexist)
          .filter(a => !otherAttractions?.find(o => a.location === a.location && isSamePlace(a.place, o.place)));
        setAttractions(all);
      });
      setSelectedAttractions([...thisAttractions]);
    }
  }, [open]);

  const handleSubmit = () => {
    day.attractions = selectedAttractions;
    onClose && onClose(true);
  };

  const handleClose = () => {
    onClose && onClose(false);
  };

  function findSelected(attraction: Attraction) {
    return selectedAttractions.findIndex(a => a.place === attraction.place && a.location === attraction.location);
  }

  // flip selected attraction
  function flipSelect(attraction: Attraction) {
    const idx = findSelected(attraction);

    if(idx >= 0) {
      selectedAttractions.splice(idx, 1);
    }
    else {
      selectedAttractions.push(attraction);
    }

    setSelectedAttractions([...selectedAttractions]);
    // sort selected attractions to top
    setAttractions(attractions.sort((a, b) => {
      const i1 = findSelected(a);
      const i2 = findSelected(b);
      const MAX = 999;
      return (i1 >= 0 ? i1 : MAX) - (i2 >= 0 ? i2 : MAX);
    }));
  }

  function deleteAttraction(idx: number) {
    const arr = [...attractions];
    arr.splice(idx, 1);
    setAttractions(arr);
  }

  const loading = attractions.length == 0;

  return (
    <Dialog open={!!open} onClose={handleClose} className='attractions-dialog' maxWidth='md'>
      <DialogTitle>{!loading ? 'Select Attractions' : ''}</DialogTitle>
      <DialogContent className={`attractions-dialog-container ${loading ? 'loading' : ''}`}>
        <div className='attractions-container'>
          {attractions.map((attraction, idx) => (
            <AttractionItem attraction={attraction} selected={findSelected(attraction) >= 0}
              onSelect={flipSelect} onDelete={() => deleteAttraction(idx)} key={idx} />
          ))}
          {loading ?
            <div className='loading-container'>
              <Typography variant='h5' textAlign={'center'}>Researching attractions...</Typography>
              <LoadingScreen/>
            </div> : null
          }
        </div>
      </DialogContent>
      <DialogActions className='command-container'>
        <Button variant='contained' onClick={handleClose} color='secondary'>Cancel</Button>
        <Button variant='contained' onClick={handleSubmit}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};